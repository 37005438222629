/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  Autocomplete,
  Box,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CustomButton } from "../elements/button";
import { CustomInput } from "../elements/input";
import { Runner, VenueSection } from "../types";
import { GET_VENUES } from "../graphql/query";
import { Venue } from "../types";
import { CustomSelect, MenuItemType } from "../elements/select";
import { toggleConfig } from "../toggles";
import { Text } from "../elements/text";
import { schema } from "./validations/runner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useQueryHook } from "../hooks/use-query.hook";
import { getStadiumSections } from "../utils/utils";

interface Props {
  onSubmit?: (data: Runner) => void;
  data?: Runner;
  isEdit: boolean;
  submitButtonText?: string;
}

export const RunnerForm = (props: Props) => {
  const { data = { getVenues: [] } } = useQueryHook({ query: GET_VENUES });

  const venueSelectData = data.getVenues?.map((venue: Venue) => {
    const menuItem: MenuItemType = {
      value: (venue._id || venue.id) as string,
      label: venue.name,
    };
    return menuItem;
  });

  const [name, setName] = useState(props.data?.name);
  const [email, setEmail] = useState(props.data?.email);
  const [phoneNumber, setPhoneNumber] = useState(props.data?.phoneNumber);
  const [address, setAddress] = useState(props.data?.address);
  const [tfn, setTfn] = useState(props.data?.tfn);
  const [bankAccount, setBankAccount] = useState(props.data?.bankAccount);
  const [superAnnuationFund, setSuperAnnuationFund] = useState(props.data?.tfn);
  const [bsb, setBsb] = useState(props.data?.BSB);
  const [venueID, setVenueID] = useState(props.data?.venueID);
  const [sectionIDs, setSectionIDs] = useState<string[]>(
    props.data?.sectionIDs || []
  );
  const [disabled, setDisabled] = useState(props.data?.isDisabled);
  const [error, setError] = useState("");

  const getSelectedVenueSections = (): VenueSection[] => {
    const sections = getStadiumSections(data, venueID);
    return sections.filter((section) => sectionIDs.includes(section.id));
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { onSubmit = () => {} } = props;
    if (validateFields()) {
      onSubmit({
        name: name || "",
        email: email || "",
        phoneNumber: phoneNumber || "",
        address: address || "",
        tfn: tfn || "",
        bankAccount: bankAccount || "",
        superAnnuationFund: superAnnuationFund || "",
        BSB: bsb || "",
        venueID: venueID || "",
        sectionIDs: sectionIDs || [],
        isDisabled: disabled || false,
      });
    }
  };

  const validateFields = () => {
    const { error, value } = schema.validate({
      name: name,
      address: address,
      tfn: tfn,
      bankAccount: bankAccount,
      contactPhone: phoneNumber,
      saf: superAnnuationFund,
      email: email,
      bsb: bsb,
      sections: sectionIDs,
    });
    if (!error) {
      setError("");
      return true;
    }
    setError(error.message);
    return false;
  };

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomSelect
            required
            data={venueSelectData}
            onChange={setVenueID}
            title="Stadium"
            value={venueID}
          />
        </Grid>

        {toggleConfig.assignVenueSectionsToRunner && (
          <Grid item xs={12}>
            <Typography>Sections</Typography>
            <Autocomplete
              multiple={true}
              id="tags-standard"
              options={getStadiumSections(data, venueID)}
              getOptionLabel={(section: VenueSection) => section.label}
              value={getSelectedVenueSections()}
              onChange={(e, values) =>
                setSectionIDs(values.map((value) => value.id))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <CustomInput required onChange={setName} title="Name" value={name} />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            required
            onChange={setEmail}
            disabled={props.isEdit}
            title="Email"
            value={email}
          />
        </Grid>

        <Grid item xs={12}>
          <PhoneInput
            country={"au"}
            autoFormat={false}
            value={phoneNumber}
            onChange={(val) => {
              setPhoneNumber("+" + val);
            }}
            disableDropdown
            placeholder="Enter phone number"
            inputStyle={{
              outline: "none",
              width: "100%",
              height: 40,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            required
            onChange={setAddress}
            title="Address"
            value={address}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            onChange={setTfn}
            title="TFN"
            value={tfn}
            helperText={"Optional"}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            onChange={setBankAccount}
            title="Bank Account"
            value={bankAccount}
            helperText={"Optional"}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            onChange={setBsb}
            title="BSB"
            value={bsb}
            helperText={"Optional"}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            onChange={setSuperAnnuationFund}
            title="Superannuation Fund"
            value={superAnnuationFund}
            helperText={"Optional"}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mb={1} display={"flex"} alignItems={"center"}>
            <Text text={"Disable the runner"}></Text>
            <Switch
              checked={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDisabled(e.target.checked);
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            mb={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
          >
            {error && <Text text={error} style={{ color: "red" }} />}
          </Box>
          <CustomButton title={props.submitButtonText || "Create"} />
        </Grid>
      </Grid>
    </form>
  );
};

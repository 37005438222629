import React from "react";
import { Box, CircularProgress } from "@mui/material";

interface Props {
  visible?: boolean;
}

export const Loader = ({ visible = false }: Props) => {
  if (!visible) {
    return <></>;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        justifyContent: "center",
        width: "100%",
        top: 0,
        left: 0,
        zIndex: 20000,
      }}
    >
      <Box
        width={60}
        height={60}
        zIndex={20000}
        sx={{ opacity: "100" }}
        lineHeight={60}
        m="auto"
      >
        <CircularProgress size={40} />
      </Box>
    </Box>
  );
};

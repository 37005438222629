export const extractAllErrorMessages = (error: any): string[] => {
  const result: string[] = [];
  error?.graphQLErrors.map((e: any) => {
    const response: any = e?.extensions?.response;
    result.push(...(response?.message || ""));
  });

  if (error?.networkError) {
    result.push("Error processing request. Please contact Admin.");
  }

  if (error?.message) {
    result.push(error?.message);
  }
  return result;
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import React, { MouseEventHandler } from "react";

interface Props {
  handleCancel?: MouseEventHandler | undefined;
  handleContinue?: MouseEventHandler | undefined;
  open?: boolean;
  title?: string;
  text?: string;
}
export const ConfirmationDailog = ({
  handleCancel = () => {},
  handleContinue = () => {},
  title,
  open = false,
  text = "",
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      PaperComponent={(props) => <Paper {...props} />}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        sx={{ minWidth: 300 }}
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleContinue}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

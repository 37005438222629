import { Typography } from "@mui/material";
import React from "react";
import { customTheme } from "../theme";

interface Props {
  text?: string;
  fontWeight?: number;
  fontSize?: number;
  color?: string;
  style?: React.CSSProperties;
}

export const Text = ({
  text,
  fontWeight,
  fontSize = 16,
  color = "",
  style,
}: Props) => {
  return (
    <Typography
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontFamily={customTheme.fontFamily}
      color={color}
      sx={{
        ...style,
      }}
    >
      {text}
    </Typography>
  );
};

import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import { CustomButton } from "../elements/button";
import { CustomInput } from "../elements/input";
import { Charges } from "../types";

interface Props {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (_data: Charges) => void;
  data: Charges;
}

export const ChargesForm = (props: Props) => {
  const [markupCharge, setMarkupPrice] = useState<number>(
    props.data.markupCharge
  );
  const [seatDeliveryCharge, setSeatDeliveryCharge] = useState<number>(
    props.data.seatDeliveryCharge
  );
  const [collectionPointDeliveryCharge, setCollectionPointDeliveryCharge] = useState<number>(
    props.data.collectionPointDeliveryCharge
  );
  const [serviceCharge, setServiceCharge] = useState<number>(
    props.data.serviceCharge
  );

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ markupCharge, seatDeliveryCharge, serviceCharge, collectionPointDeliveryCharge });
  };

  return (
    <form onSubmit={submit}>
      <Grid container>
        <Grid item xs={12} sm={6} m="auto">
          <Grid mt={2}>
            <CustomInput
              min={0}
              max={100}
              type="number"
              title="Markup Price (%)"
              value={markupCharge}
              required={true}
              onChange={(val) => setMarkupPrice(val)}
            />
          </Grid>
          <Grid mt={2}>
            <CustomInput
              type="number"
              min={0}
              max={100}
              value={seatDeliveryCharge}
              required
              onChange={(val) => setSeatDeliveryCharge(val)}
              title="To The Seat Delivery Charges (%)"
            />
          </Grid>
          <Grid mt={2}>
            <CustomInput
              type="number"
              min={0}
              max={100}
              value={collectionPointDeliveryCharge}
              required
              onChange={(val) => setCollectionPointDeliveryCharge(val)}
              title="Collection Point Delivery Charges (%)"
            />
          </Grid>
          <Grid mt={2}>
            <CustomInput
              type="number"
              min={0}
              max={100}
              value={serviceCharge}
              required
              onChange={(val) => setServiceCharge(val)}
              title="Service Charges (%)"
            />
          </Grid>
          <Grid mt={2}>
            <CustomButton title="Submit" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

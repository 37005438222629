import PREVIEW_TTS from "../assets/previews/preview-tts.png";
import PREVIEW_CNC from "../assets/previews/preview-cnc.png";
import PREVIEW_VENUE_MAP from "../assets/previews/preview-venue-map.png";
import PREVIEW_VENDOR_BANNER from "../assets/previews/preview-vendor-banner.png";

export const previewImages = {
  PREVIEW_TTS,
  PREVIEW_CNC,
  PREVIEW_VENUE_MAP,
  PREVIEW_VENDOR_BANNER,
};

import Joi from "joi";

export const schema = Joi.object().keys({
  name: Joi.string()
    .trim()
    .regex(/^[A-Za-z- ]+$/)
    .required()
    .label("Name")
    .messages({
      "string.pattern.base": `"Name" should only contain alphabets`,
    }),
  address: Joi.string().trim().required().label("Address"),
  tfn: Joi.string().trim().length(9).allow("").label("TFN"),
  bankAccount: Joi.string().trim().min(4).allow("").label("Bank Account"),
  bsb: Joi.string().trim().length(6).allow("").label("BSB Number"),
  contactPhone: Joi.string()
    .trim()
    .required()
    .regex(/^[0-9\-[\]+]{13}$/)
    .messages({
      "string.pattern.base": `Phone number must have 10 digits. Followed with (+61)`,
    })
    .label("Contact"),
  saf: Joi.string().trim().allow("").label("Superannuation Fund"),
  email: Joi.string()
    .trim()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: false,
    })
    .label("Email"),
  sections: Joi.array()
    .items(Joi.string().trim().required())
    .required()
    .label("Sections"),
});

import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { DeliveryArea, VenueSection } from "../../types";
import { v4 } from "uuid";
import CollectionPointInput from "./collection-point-input";
import DeliveryAreaInput from "./delivery-area-input";

interface DelivaryAreaProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  id?: string;
  title?: string;
  deliveryAreas?: DeliveryArea[] ;
  isMandatory?: boolean;
  venueSections: VenueSection[];
}

export const DeliveryAreas = (props: DelivaryAreaProps) => {
  const [deliveryAreas, setDeliveryAreas] = useState<DeliveryArea[]>(
    props.deliveryAreas || []
  );

  useEffect(() => {
    props.onChange(deliveryAreas);
  }, [deliveryAreas]);

  const deleteDeliveryArea = (
    deliveryAreaId: string | null | undefined
  ) => {
    const newDeliveryAreas = deliveryAreas.map((deliveryArea) => {
      if (deliveryArea.id === deliveryAreaId) {
        deliveryArea.isDeleted = true;
        deliveryArea.serviceableSectionsIds = [];
      }
      return deliveryArea;
    });
    setDeliveryAreas(newDeliveryAreas);
  };

  const onChangeDeliveryArea = (
    index: number,
    updatedDeliveryArea: DeliveryArea
  ) => {
    const newDeliveryAreas: DeliveryArea[] = [...deliveryAreas];
    newDeliveryAreas[index] = updatedDeliveryArea;
    setDeliveryAreas(newDeliveryAreas);
  };

  return (
    <FormControl fullWidth>
      <Box display="flex" mb={2}>
        <Box pt={0.5} mr={2}>
          <Typography>{props.title}</Typography>
        </Box>
      </Box>

      {deliveryAreas.map((deliveryArea, index: number) => {
        if (deliveryArea.isDeleted) return;
        return (
          <DeliveryAreaInput
            key={deliveryArea.id}
            deliveryArea={deliveryArea}
            index={index}
            deleteDeliveryArea={deleteDeliveryArea}
            onChangeDeliveryArea={onChangeDeliveryArea}
            venueSections={props.venueSections}
          />
        );
      })}
      <Box display={"flex"} mb={2} flexDirection={"column"}>
        <Button
          variant="outlined"
          style={{
            textTransform: "none",
          }}
          onClick={() => {
            setDeliveryAreas([
              ...deliveryAreas,
              { label: "", id: v4() } as DeliveryArea,
            ]);
          }}
        >
          Add Delivery Area
        </Button>
      </Box>
    </FormControl>
  );
};

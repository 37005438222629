import Joi from "joi";

export const vendorSchema = Joi.object().keys({
  businessName: Joi.string()
    .trim()
    .required()
    .messages({
      "string.pattern.base": `"Business Name" - No special characters allowed`,
    })
    .label("Business Name"),
  tradingName: Joi.string()
    .trim()
    .required()
    .messages({
      "string.pattern.base": `"Trading Name" - No special characters allowed`,
    })
    .label("Trading Name"),
  abn: Joi.string()
    .trim()
    .optional()
    .regex(/^(\d{1})?[0-9- ]{10}$/)
    .messages({
      "string.pattern.base": `ABN/CR - Please enter a valid number. Alphabets & Special Characters not allowed`,
    })
    .allow("")
    .label("ABN"),
  bankAccount: Joi.string().trim().min(4).allow("").label("Bank Account"),
  bsb: Joi.string().trim().optional().length(6).allow("").label("BSB Number"),
  contactPhone: Joi.string()
    .trim()
    .when("dialCode", {
      is: Joi.string().exist(),
      then: Joi.string()
        .custom((value, helpers) => {
          const dialCode = helpers.state.ancestors[0].dialCode;
          if (!value.startsWith(`+${dialCode}`) && value !== "+") {
            return helpers.message({ custom: `"{{#label}}" is not valid.` });
          } else if (value.trim().slice(`+${dialCode}`.length).length == 0) {
            return helpers.message({ custom: `"{{#label}}" is required` });
          }
          return value;
        })
        .min(8)
        .max(16)
        .required(),
      otherwise: Joi.string().min(4).required(),
    })
    .label("Phone Number")
    .messages({
      "string.min": `"{{#label}}" is not valid.`,
      "string.max": `"{{#label}}" is not valid.`,
    }),
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: false,
    })
    .label("Email"),
  imageUrl: Joi.string().optional().allow("").uri().label("Image"),
  sections: Joi.array().label("Sections"),
  dialCode: Joi.any(),
});

import React, { useState } from "react";

import { Resource } from "../resource/resource-config";
import { ResourceList } from "./resource-list";
import { RefundOrder } from "../types/order";
import RefundOrderDetailModal from "../components/refund/refund-order-detail-modal";

interface Props {
  resource: Resource;
}

const RefundsManagementDashboard = (props: Props) => {
  const { resource } = props;

  const [openModalId, setOpenModalId] = useState("");
  const [selectedRefundOrder, setSelectedRefundOrder] =
    useState<RefundOrder | null>(null);

  const eventHandler = (eventData: never) => {
    const { item, } = eventData;
    setSelectedRefundOrder(item);
    setOpenModalId("refund-order-details");

  }


  return (
    <div>
      {selectedRefundOrder && openModalId === "refund-order-details" && (
        <RefundOrderDetailModal
          openModal={openModalId === "refund-order-details"}
          refundOrder={selectedRefundOrder}
          resourcePath={resource.path}
          onClose={() => {
            setOpenModalId("");
          }}
        />
      )}
      <ResourceList onRowClick={eventHandler} resource={resource} onEventEmit={eventHandler}></ResourceList>
    </div>
  );
};

export default RefundsManagementDashboard;

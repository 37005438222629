import * as React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { MenuItem, Menu, Box } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Notification } from "./notification";
import { AppContext } from "../context/app-context";
import { useContext } from "react";
import { CLEAR_DEVICE } from "../graphql/mutation";
import { UserType } from "../types";
import { useNavigate } from "react-router-dom";
import { useMutationHook } from "../hooks/use-mutation.hook";

interface Props {
  handleDrawerToggle?: React.MouseEventHandler | undefined;
}

export const CustomAppBar = ({ handleDrawerToggle }: Props) => {
  const { signOut } = useAuthenticator();
  const { adminID } = useContext(AppContext);
  const navigate = useNavigate();
  const { mutate: updateResource } = useMutationHook({
    mutation: CLEAR_DEVICE,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const signOutHandler = async () => {
    navigate("/");
    clearDevice();
    signOut();
    localStorage.clear();
    location.reload();
  };

  const clearDevice = () => {
    if (adminID?.value) {
      updateResource({
        variables: {
          input: {
            id: adminID?.value,
            userType: UserType.admin,
          },
        },
      });
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#F3F3F3",
      }}
    >
      <Toolbar
        sx={{
          color: "#300304",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }} variant="h6" noWrap component="div">
          Admin Dashboard
        </Typography>

        <Box display="flex">
          <Box mr={4} display="flex" alignItems={"center"}>
            <Notification />
          </Box>
          <Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={signOutHandler}>Sign out</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

type Currency = {
  currencyName: string;
  currencySymbol: string;
  currentConversionRate: string;
};

async function getSingleCurrencyDataFromStripe(value: any) {
  return {
    currencyName: "aud",
    currencySymbol: "A$",
    currentConversionRate: "2.7",
  };
}

async function getCurrenciesFromStripe() {
  return { currencies: ["a", "b"] };
}

export const CreateVenue = () => {
  const [currencies, setCurrencies] = React.useState<Array<string>>();
  const [selectedCurrency, setSelectedCurrency] = React.useState<Currency>();

  const fetchAndSetCurrencies = async () => {
    // have query to fetch currencies from stripe
    const data = await getCurrenciesFromStripe();
    setCurrencies(data.currencies);
  };

  const fetchSelectedCurrency = async (e: any) => {
    // have query to fetch data about one currency from Stripe
    const data = await getSingleCurrencyDataFromStripe(e.target.value);
    setSelectedCurrency(data);
  };

  React.useEffect(() => {
    fetchAndSetCurrencies();
  }, []);

  return (
    <div>
      <form>
        {currencies && (
          <select onChange={fetchSelectedCurrency}>
            <option disabled>Currency</option>
            {currencies.map((currency, ind) => (
              <option key={ind}>{currency}</option>
            ))}
          </select>
        )}
        {selectedCurrency && (
          <p>
            Current Conversion Rate: {selectedCurrency.currentConversionRate}
          </p>
        )}
      </form>
    </div>
  );
};

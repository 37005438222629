import { useEffect, useState } from "react";
import { GET_CHARGES } from "../graphql/query";
import { UPDATE_CHARGES } from "../graphql/mutation";
import { ChargesForm } from "../forms/charges-form";
import { Charges } from "../types";
import { Loader } from "../elements/loader";
import { Alert, AlertColor, Box, Snackbar } from "@mui/material";
import { ConfirmationDailog } from "../elements/confirmation-dialog";
import { useLocation } from "react-router-dom";
import { useQueryHook } from "../hooks/use-query.hook";
import { useMutationHook } from "../hooks/use-mutation.hook";

export const ChargesManagementDashboard = () => {
  const defaultOptions = {
    message: "",
    visible: false,
    variant: "success",
  };

  const [showSnackbar, setShowSnackbar] = useState(defaultOptions);
  const [openModel, setOpenModel] = useState(false);
  const [charges, setCharges] = useState<Charges>();
  const location = useLocation();

  const {
    data = {
      getCharges: {
        deliveryCharge: 0,
        markupCharge: 0,
        serviceCharges: 0,
      },
    },
    loading: fetchLoading,
    fetch: fetchCharges,
  } = useQueryHook({ query: GET_CHARGES });

  const { mutate: updateCharges } = useMutationHook({
    mutation: UPDATE_CHARGES,
    onCompleted: () => {
      setShowSnackbar({
        message: "Successfully updated",
        visible: true,
        variant: "success",
      });
    },
    onError: (e) => {
      setShowSnackbar({
        message: "Faild to updated",
        visible: true,
        variant: "error",
      });
      console.log(e);
    },
  });

  const submit = (data: Charges) => {
    setCharges(data);
    setOpenModel(true);
  };

  const handleClose = () => {
    setShowSnackbar(defaultOptions);
  };

  const submitCharges = () => {
    updateCharges({
      variables: {
        charges: charges,
      },
    });
    setOpenModel(false);
  };

  useEffect(() => {
    fetchCharges();
  }, [location]);

  if (fetchLoading) {
    return <Loader visible={true} />;
  }

  return (
    <>
      <ConfirmationDailog
        title={`Do you want to update the charges?`}
        open={openModel}
        handleCancel={() => setOpenModel(false)}
        handleContinue={submitCharges}
      />
      <Snackbar
        open={showSnackbar.visible}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box minWidth={300}>
          <Alert variant="filled" severity={showSnackbar.variant as AlertColor}>
            {showSnackbar.message}
          </Alert>
        </Box>
      </Snackbar>

      <ChargesForm
        onSubmit={submit}
        data={{
          markupCharge: data.getCharges?.markupCharge,
          seatDeliveryCharge: data.getCharges?.seatDeliveryCharge,
          collectionPointDeliveryCharge: data.getCharges?.collectionPointDeliveryCharge,
          serviceCharge: data.getCharges?.serviceCharge,
        }}
      />
    </>
  );
};

import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import { Outlet, useNavigate } from 'react-router-dom'
import { Resources } from '../resource/resource-config'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import { CustomAppBar } from '../components/app-bar'
import { GridOn } from '@mui/icons-material'
import { LocalMall } from '@mui/icons-material'

const drawerWidth = 250

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

export default function Dashboard(props: Props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {Object.keys(Resources).map((key, index) => {
          return (
            <ListItem
              key={key}
              onClick={() => {
                setMobileOpen(false)
                navigate(Resources[key].path + '/list')
              }}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? (
                    <ManageAccountsOutlinedIcon />
                  ) : (
                    <ManageAccountsIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={`${Resources[key].name}s`} />
              </ListItemButton>
            </ListItem>
          )
        })}

        <ListItem
          key="charges"
          onClick={() => {
            setMobileOpen(false)
            navigate('/charges')
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <AttachMoneyOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={`Charges`} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="orders"
          onClick={() => {
            setMobileOpen(false)
            navigate('/orders')
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <LocalMall />
            </ListItemIcon>
            <ListItemText primary={`Orders`} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="report"
          onClick={() => {
            setMobileOpen(false)
            navigate('/report')
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <GridOn />
            </ListItemIcon>
            <ListItemText primary={`Reports`} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="refund"
          onClick={() => {
            setMobileOpen(false)
            navigate('/refund/list')
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <MoneyOffIcon />
            </ListItemIcon>
            <ListItemText primary={`Refund Requests`} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <CustomAppBar handleDrawerToggle={handleDrawerToggle} />

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>

      <Box p={1} sx={{ width: '100%', height: 'auto' }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}

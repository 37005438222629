/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import React, { useState } from "react";
import { Autocomplete, Box, Grid, Switch, TextField } from "@mui/material";
import { CustomButton } from "../elements/button";
import { CustomInput } from "../elements/input";
import { CustomSelect, MenuItemType } from "../elements/select";
import { GET_VENUES } from "../graphql/query";
import { Vendor, Venue, VenueSection } from "../types";
import { Text } from "../elements/text";
import { vendorSchema } from "./validations/vendor";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ImageUpload from "../components/Image-upload/image-upload";
import { UploadType } from "../types/file";
import { useQueryHook } from "../hooks/use-query.hook";
import { useMutationHook } from "../hooks/use-mutation.hook";
import { DUPLICATE_VENDOR } from "../graphql/mutation";
import { useNavigate } from "react-router-dom";
import { FormToolTip } from "../elements/form-tooltip";
import { getStadiumSections } from "../utils/utils";

interface Props {
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (data: Vendor) => void;
  data?: Vendor;
  submitButtonText?: string;
  isEdit?: boolean;
  duplicateData?: Record<string, any>;
}

export const VendorForm = (props: Props) => {
  const { data = { getVenues: [] } } = useQueryHook({ query: GET_VENUES });
  const { duplicateData } = props;
  const venueSelectData = data.getVenues?.map((venue: Venue) => {
    const menuItem: MenuItemType = {
      value: (venue._id as string) || (venue.id as string),
      label: venue.name,
    };
    return menuItem;
  });

  const navigate = useNavigate();
  const { mutate: duplicateVendor, loading: mutationLoading } = useMutationHook(
    {
      mutation: DUPLICATE_VENDOR,
      onCompleted: () => {
        console.log("completed");
        navigate(`/vendors/list`);
      },
    }
  );

  const [businessName, setBusinessName] = useState(
    duplicateData ? duplicateData.data?.businessName : props.data?.businessName
  );
  const [abn, setAbn] = useState(props.data?.abn);
  const [tradingName, setTradingName] = useState(
    duplicateData ? duplicateData.data?.tradingName : props.data?.tradingName
  );
  const [imageUrl, setImageUrl] = useState(
    duplicateData ? duplicateData.data?.imageUrl : props.data?.imageUrl
  );
  const [bankAccountNumber, setBankAccountNumber] = useState(
    props.data?.bankAccount
  );
  const [bsb, setBsb] = useState(props.data?.BSB);
  const [contactPhone, setContactPhone] = useState(props.data?.contactPhone);
  const [contactEmail, setContactEmail] = useState(props.data?.contactEmail);
  const [hoursOfOperation] = useState(props.data?.hoursOfOperation);
  const [disabled, setDisabled] = useState(props.data?.isDisabled);
  const [stallID, setStallID] = useState(props.data?.stallID);
  const [venueID, setVenueID] = useState(props.data?.venueID);
  const [sectionIDs, setSectionIDs] = useState<string[]>(
    props.data?.sectionIDs || []
  );
  const [error, setError] = useState("");
  const [dialCode, setDialCode] = useState("");

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if (duplicateData) {
    //   if (validateFields()) {
    //     duplicateVendor({
    //       variables: {
    //         originalVendorId: duplicateData.data._id,
    //         vendor: {
    //           businessName: businessName || "",
    //           tradingName: tradingName || "",
    //           imageUrl: imageUrl || "",
    //           abn: abn || "",
    //           bankAccount: bankAccountNumber || "",
    //           BSB: bsb || "",
    //           contactPerson: "",
    //           contactPhone: contactPhone || "",
    //           contactEmail: contactEmail || "",
    //           hoursOfOperation: hoursOfOperation || "0",
    //           isDisabled: disabled || false,
    //           venueID: venueID || "",
    //           sectionIDs: sectionIDs || [],
    //           stallID: stallID || "",
    //         },
    //       },
    //     });
    //   }
    //   return;
    // }
    const { onSubmit = () => {} } = props;
    if (validateFields()) {
      onSubmit({
        businessName: businessName || "",
        tradingName: tradingName || "",
        imageUrl: imageUrl || "",
        abn: abn || "",
        bankAccount: bankAccountNumber || "",
        BSB: bsb || "",
        contactPerson: "",
        contactPhone: contactPhone || "",
        contactEmail: contactEmail || "",
        hoursOfOperation: hoursOfOperation || "0",
        isDisabled: disabled || false,
        venueID: venueID || "",
        sectionIDs: sectionIDs || [],
        stallID: stallID || "",
      });
    }
  };

  const validatePhoneNumber = (value: string, countryCode: string) => {
    return (
      value.startsWith(countryCode) &&
      value.trim().slice(countryCode.length).length > 0
    );
  };

  const getSelectedVenueSections = (): VenueSection[] => {
    const sections = getStadiumSections(data, venueID);
    return sections.filter((section) => sectionIDs.includes(section.id));
  };

  const validateFields = () => {
    const { error } = vendorSchema.validate({
      sections: sectionIDs,
      businessName: businessName?.trim(),
      tradingName: tradingName?.trim(),
      abn: abn,
      bankAccount: bankAccountNumber,
      bsb: bsb,
      contactPhone: contactPhone,
      email: contactEmail,
      imageUrl: imageUrl,
      dialCode: dialCode,
    });

    if (!error) {
      setError("");
      return true;
    }
    setError(error.message);
    return false;
  };

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomSelect
            data={venueSelectData}
            onChange={setVenueID}
            title="Stadium"
            value={venueID}
          />
        </Grid>
        <Grid item xs={12}>
          <Text text={"Sections"} />
          <Autocomplete
            multiple={true}
            id="tags-standard"
            options={getStadiumSections(data, venueID)}
            getOptionLabel={(section: VenueSection) => section.label}
            value={getSelectedVenueSections()}
            onChange={(e, values) =>
              setSectionIDs(values.map((value) => value.id))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            required
            onChange={setStallID}
            title="Stall ID"
            value={stallID}
            isMandatory={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            required
            onChange={setBusinessName}
            title="Business Name"
            value={businessName}
            isMandatory={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            required
            onChange={setTradingName}
            title="Trading Name"
            value={tradingName}
            isMandatory={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput onChange={setAbn} title="ABN/CR Number" value={abn} />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            onChange={setBankAccountNumber}
            title="Bank Account No."
            value={bankAccountNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput onChange={setBsb} title="BSB" value={bsb} />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            inputProps={{ required: true }}
            isValid={(inputNumber, country: any) => {
              setDialCode(country.dialCode);
              return validatePhoneNumber(inputNumber, country.dialCode);
            }}
            country={"au"}
            autoFormat={false}
            value={contactPhone}
            onChange={(val) => {
              setContactPhone("+" + val);
            }}
            disableDropdown
            placeholder="Enter phone number"
            inputStyle={{
              outline: "none",
              width: "100%",
              height: 40,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            required
            disabled={props.isEdit}
            onChange={setContactEmail}
            title="Email"
            value={contactEmail}
            isMandatory={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display={"inline-flex"} alignItems={"center"} mb={1}>
            <Text text="Business Logo" />
            <FormToolTip text="This is used to display on the menu page of patron app" />
          </Box>
          <ImageUpload
            onUpload={setImageUrl}
            src={imageUrl}
            imageType={UploadType.LOGO}
            name={businessName?.replace(/\s/g, "")}
            imageNote={
              "This is the image displayed on the vendor menu page and order status page of patron app"
            }
          />
          <Box mt={1}>
            <Text
              text="Please ensure image size is less than 1 MB (.JPG, .PNG Only)"
              fontSize={14}
              color={"#777777"}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display={"flex"} alignItems={"center"}>
            <Text text={"Disable the vendor"}></Text>
            <Switch
              checked={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDisabled(e.target.checked);
              }}
            />
          </Box>
        </Grid>

        <Box
          display={"flex"}
          alignItems={"center"}
          textAlign={"center"}
          marginTop={"1vh"}
          ml={"0.7vw"}
        >
          <Text
            style={{ marginLeft: 1, marginRight: 1 }}
            text=" * "
            fontSize={15}
            color={"red"}
          />
          <Text text="indicates a required field" fontSize={15} />
        </Box>

        <Grid item xs={12}>
          <CustomButton
            title={props.submitButtonText || "Create"}
          ></CustomButton>
          <Box
            height={"2.5vh"}
            mt={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
          >
            {error && <Text text={error} fontSize={15} style={{ color: "red" }} />}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

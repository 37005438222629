import React from "react";
import { useState } from "react";
import { Alert, AlertColor, Backdrop, Box, Grid, Modal, Snackbar, TextareaAutosize, Typography } from "@mui/material";
import { CustomButton } from "../../elements/button";
import { CustomInput } from "../../elements/input";
import { TerminatingRefundStatus } from "../../types";
import { Text } from "../../elements/text";
import { schema } from "./validations/accept";
import { useMutationHook } from "../../hooks/use-mutation.hook";
import { ACCEPT_REFUND_REQUEST } from "../../graphql/mutation";
import { RefundOrder } from "../../types/order";

interface Props {
    // eslint-disable-next-line no-unused-vars
    refundOrder: RefundOrder;
    openModal: boolean;
    onClose: () => void;
}

export const AcceptRefundModal = (props: Props) => {
    const [closeDate, setCloseDate] = useState<Date | undefined>(
        props.refundOrder.closeDate
    );
    const [amountRefunded, setAmountRefunded] = useState<
        number | undefined
    >(props.refundOrder.amountRefunded || props.refundOrder.amountRequested);
    const [reviewerComments, setReviewerComments] = useState<string[]>(
        props.refundOrder.issueDescription?.reviewerComments || []
    );


    const [error, setError] = useState<string | undefined>()

    const defaultOptions = {
        message: "",
        visible: false,
        variant: "success",
    };
    const [showSnackbar, setShowSnackbar] = useState(defaultOptions);

    const { mutate: updateRefundStatusRequest } = useMutationHook({
        mutation: ACCEPT_REFUND_REQUEST,
        onCompleted: () => {
            setShowSnackbar({
                message: "Successfully updated",
                visible: true,
                variant: "success",
            });
            window.location.reload();
        },
        onError: (e) => {
            setShowSnackbar({
                message: "Faild to updated",
                visible: true,
                variant: "error",
            });
            console.log(e);
        },
    });

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateDetails()) {
            updateRefundStatusRequest({
                variables: {
                    id: props.refundOrder._id,
                    acceptRefundRequestInput: {
                        amount: amountRefunded,
                        refundStatus: amountRefunded === props.refundOrder.amountRequested ? TerminatingRefundStatus.REFUNDED : TerminatingRefundStatus.PARTIALLY_REFUNDED
                    }
                }
            })
        }
    };

    const validateDetails = () => {
        const { error } = schema.validate({
            closeDate: closeDate,
            reviewerComments: reviewerComments,
        });

        if (!error) {
            setError("");
            return true;
        }
        setError(error.message);
        return false;
    }

    return (
        <>
            <Snackbar
                open={showSnackbar.visible}
                autoHideDuration={3000}
                onClose={() => { setShowSnackbar(defaultOptions); }
                }
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <Box minWidth={300}>
                    <Alert variant="filled" severity={showSnackbar.variant as AlertColor}>
                        {showSnackbar.message}
                    </Alert>
                </Box>
            </Snackbar>
            <Modal
                open={props.openModal}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        overflow: "auto",
                        p: 4,
                    }}
                    width={"60%"}
                >
                    <Typography variant="h4" textAlign={"center"}>
                        Accept Refund Request
                    </Typography>
                    {error && <Text text={error} style={{ color: "red" }} />}
                    <form onSubmit={submit}>
                        <Grid item xs={12} sm={6} m={"auto"}>
                            <Grid mt={2}>
                                <CustomInput
                                    type="Date"
                                    value={
                                        closeDate
                                            ? new Date(closeDate).toISOString().substring(0, 10)
                                            : new Date().toISOString().substring(0, 10)
                                    }
                                    required
                                    onChange={(val) => setCloseDate(val)}
                                    title="Close Date"
                                />
                            </Grid>
                            <CustomInput
                                type="number"
                                min={0}
                                value={amountRefunded}
                                required={true}
                                onChange={(val) => {
                                    setAmountRefunded(val)
                                }}
                                title="Amount Refunded"
                            />
                            <Grid mt={2}>
                                <TextareaAutosize
                                    style={{
                                        width: "100%",
                                        borderColor: "#c4c4c4",
                                        borderRadius: "4px",
                                    }}
                                    aria-label="reviewer comments"
                                    minRows={4}
                                    value={reviewerComments}
                                    placeholder="Please add your comments"
                                    onChange={(event) => {
                                        setReviewerComments([event.target.value]);
                                    }}
                                />
                            </Grid>
                            <Grid mt={2}>
                                <CustomButton title="Accept Request" />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

import jwtDecode, { JwtPayload } from "jwt-decode";

export const isTokenExpired = (token: string | undefined) => {
  if (token !== null) {
    const currentDate = Date.now() / 1000;
    const decodeToken: JwtPayload = jwtDecode(token as string);
    if (decodeToken?.exp) {
      const toRefreshTime = decodeToken?.exp;
      return currentDate > toRefreshTime ? true : false;
    }
  }
  return true;
};

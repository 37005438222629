import { ClickAwayListener, Tooltip, Zoom, IconButton } from "@mui/material";
import { useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


interface TooltipProps {
	text: string;
}

export const FormToolTip = (props: TooltipProps) => {
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};
	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<Tooltip
				PopperProps={{
					disablePortal: true,
				}}
				onClose={handleTooltipClose}
				open={open}
				TransitionComponent={Zoom}
				disableHoverListener
				title={props.text}
			>
				<IconButton onClick={handleTooltipOpen} sx={{ py: 0 }} >
					<InfoOutlinedIcon style={{ fontSize: '20px' }} />
				</IconButton>
			</Tooltip>
		</ClickAwayListener>
	)
}
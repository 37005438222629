import { Grid } from "@mui/material";
import { CustomInput } from "./input";

const SearchBar = ({searchQuery, setSearchQuery}:{searchQuery:string, setSearchQuery:React.Dispatch<React.SetStateAction<string>>}) => {
    return (
      <>
        <Grid
          sx={{
            padding:0,
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}
        >
          <CustomInput
            autoFocus={true}
            type=""
            value={searchQuery}
            label="Search orderID"
            onChange={setSearchQuery}
          />
        </Grid>
      </>
    );
  };
  export default SearchBar
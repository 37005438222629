import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { CollectionPoint, VenueSection } from "../../types";
import { v4 } from "uuid";
import CollectionPointInput from "./collection-point-input";

interface CollectionPointsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  id?: string;
  title?: string;
  collectionPoints?: CollectionPoint[];
  venueSections: VenueSection[];
}

export const CollectionPoints = (props: CollectionPointsProps) => {
  const [collectionPoints, setCollectionPoints] = useState<CollectionPoint[]>(
    props.collectionPoints || []
  );

  useEffect(() => {
    props.onChange(collectionPoints);
  }, [collectionPoints]);

  const deleteCollectionPoint = (
    collectionPointId: string | null | undefined
  ) => {
    const newCollectionPoints = collectionPoints.map((collectionPoint) => {
      if (collectionPoint.id === collectionPointId) {
        collectionPoint.isDeleted = true;
        collectionPoint.serviceableSectionsIds = [];
      }
      return collectionPoint;
    });
    setCollectionPoints(newCollectionPoints);
  };

  const onChangeCollectionPoint = (
    index: number,
    updatedCollectionPoint: CollectionPoint
  ) => {
    const newCollectionPoints: CollectionPoint[] = [...collectionPoints];
    newCollectionPoints[index] = updatedCollectionPoint;
    setCollectionPoints(newCollectionPoints);
  };

  return (
    <FormControl fullWidth>
      <Box display="flex" mb={2}>
        <Box pt={0.5} mr={2}>
          <Typography>{props.title}</Typography>
        </Box>
      </Box>

      {collectionPoints.map((collectionPoint, index: number) => {
        if (collectionPoint.isDeleted) return;
        return (
          <CollectionPointInput
            key={collectionPoint.id}
            collectionPoint={collectionPoint}
            index={index}
            deleteCollectionPoint={deleteCollectionPoint}
            onChangeCollectionPoint={onChangeCollectionPoint}
            venueSections={props.venueSections}
          />
        );
      })}
      <Box display={"flex"} mb={2} flexDirection={"column"}>
        <Button
          variant="outlined"
          style={{
            textTransform: "none",
          }}
          onClick={() => {
            setCollectionPoints([
              ...collectionPoints,
              { label: "", id: v4() } as CollectionPoint,
            ]);
          }}
        >
          Add Collection Point
        </Button>
      </Box>
    </FormControl>
  );
};

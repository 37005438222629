import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { vendorOrderStatusConfig } from "../../constants/constants";
import { OptionWithType } from "../../types";
import { Text } from "../../elements/text";
import { AddSharp, ChevronRight, ExpandLess } from "@mui/icons-material";
import { customTheme } from "../../theme";
import {
  OrderedItem,
  RefundOrder,
  VendorOrder,
} from "../../types/order";

type RefundOrderDetailCardProps = {
  order: VendorOrder;
  refundOrder: RefundOrder;
};

const RefundOrderDetailCard: React.FC<RefundOrderDetailCardProps> = ({
  order,
  refundOrder,
}) => {
  const [showOrderItems, setShowOrderItems] = useState<boolean>(false);

  const handleCollapseItems = () => {
    setShowOrderItems(!showOrderItems);
  };

  return (
    <>
      <Box
        sx={{
          my: 1,
          p: 1,
          borderRadius: 2,
          border: "3px solid #D1C7C7",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box>
            <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
              <Text
                text={`Order #${order.orderNumber || ""}`}
                fontSize={14}
                fontWeight={600}
                color={"#300304"}
              />
              <Box
                sx={{
                  p: 0.5,
                  m: 0.5,
                  mr: 2,
                  borderRadius: 1,
                }}
              >
                {order?.isOrderUpdated && (
                  <Text fontWeight={700} text="(E)" color={"#950305"} />
                )}
              </Box>
            </Box>
            <Typography fontSize={14} fontWeight={500} color={"#300304"}>
              {order?.vendor?.tradingName}
            </Typography>
          </Box>
          <Box>
            <Typography
              color={vendorOrderStatusConfig[order.orderStatus].color}
              fontSize={12}
              fontWeight={500}
            >
              {vendorOrderStatusConfig[order.orderStatus].text}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#D0D5DD" }} />
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Text
            text={"Refund Request Details"}
            fontSize={14}
            fontWeight={200}
          />
          <Box display="flex" alignItems={"center"}>
            <Text
              text={`x${order.items?.length.toString()}`}
              fontSize={16}
              fontWeight={500}
            />
            {showOrderItems ? (
              <ExpandLess onClick={handleCollapseItems} />
            ) : (
              <ChevronRight onClick={handleCollapseItems} />
            )}
          </Box>
        </Box>

        <Divider sx={{ borderColor: "#D0D5DD" }} />
        {showOrderItems && (
          <Box>
            <Grid container xs={12} direction={"row"} textAlign={"center"}>
              <Grid
                item
                xs={5}
                px={"10px"}
                fontFamily={customTheme.fontFamily}
                fontSize={14}
                fontWeight={500}
              >
                Item
              </Grid>
              <Grid
                item
                xs={3}
                px={"10px"}
                fontFamily={customTheme.fontFamily}
                fontSize={14}
                fontWeight={500}
              >
                Request Refund Qty
              </Grid>
              <Grid
                item
                xs={2}
                px={"10px"}
                fontFamily={customTheme.fontFamily}
                fontSize={14}
                fontWeight={500}
              >
                Ordered Qty
              </Grid>
              <Grid
                item
                xs={2}
                px={"10px"}
                fontFamily={customTheme.fontFamily}
                fontSize={14}
                fontWeight={500}
              >
                Price
              </Grid>
            </Grid>
            <Grid container xs={12}>
              {order.items?.map((item: OrderedItem, index: number) => {
                const refundItem = refundOrder.requestedRefundItems?.find(
                  (i) => i.id === item._id
                );
                if (!refundItem) return <></>;
                let customisations = " + ";
                item.customisations?.forEach(customisatoin => {
                  customisations += `${customisatoin.name} `
                })
                const itemTitle = item.name + customisations
                return (
                  <Box sx={{ width: "100%" }} key={`${refundItem.id}-${index}`}>
                    <Grid container xs={12} px={2} direction="row" textAlign={"center"}>
                      <Grid item xs={5} px={"10px"}>
                        <Typography
                          fontFamily={customTheme.fontFamily}
                          fontSize={14}
                          fontWeight={500}
                          color={"#2A2A2A"}
                        >
                          {itemTitle}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} px={"10px"}>
                        <Typography
                          fontFamily={customTheme.fontFamily}
                          fontSize={14}
                          fontWeight={500}
                        >
                          {refundItem.quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} px={"10px"}>
                        <Typography
                          fontFamily={customTheme.fontFamily}
                          fontSize={14}
                          fontWeight={500}
                          color={"#2A2A2A"}
                        >
                          {item?.count}
                        </Typography>
                        {item?.isUpdated && (
                          <Box ml={"4px"}>
                            <Typography
                              fontFamily={customTheme.fontFamily}
                              fontSize={14}
                              fontWeight={500}
                              color={"#F84244"}
                            >
                              {item?.updatedQuantity}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={2} px={"10px"}>
                        <Typography
                          fontFamily={customTheme.fontFamily}
                          fontSize={14}
                          fontWeight={500}
                        >
                          {item.markupPrice}
                        </Typography>
                      </Grid>
                    </Grid>

                  </Box>
                );
              })}
            </Grid>
          </Box>
        )}
      </Box>
      {order?.isOrderUpdated && (
        <Box ml={2}>
          <Text
            text="(*) Item updated quantity"
            color="#EC8B8C"
            fontSize={14}
          />
        </Box>
      )}
    </>
  );
};

export default RefundOrderDetailCard;

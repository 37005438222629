import { Modal, Grid, Button, IconButton, Dialog } from "@mui/material";
import { useState } from "react";
import { Image } from "../../elements/image";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Text } from "../../elements/text";

interface ViewImageProps {
  src?: string;
  buttonTitle?: string;
  style?: React.CSSProperties;
}

export const ViewImageModal = (props: ViewImageProps) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <>
      {props.buttonTitle && <Button sx={{ ...props.style }} onClick={() => setShowImage(true)}>{props.buttonTitle}</Button>}
      {!props.buttonTitle &&
        <IconButton onClick={() => setShowImage(true)} sx={{ cursor: 'pointer', color: '#222222' }} disabled={!props.src}>
          <RemoveRedEyeIcon />
        </IconButton>}
      <Dialog
        open={showImage}
        onClose={() => {
          setShowImage(false);
        }}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <Grid
          py={2}
          container
          item
          xs={12}
          sm={10}
          m="auto"
        >
          <Button
            variant="outlined"
            onClick={() => {
              setShowImage(false);
            }}
            color="secondary"
            sx={{ marginLeft: "auto", mb: 2 }}
          >
            Close
          </Button>
          <Image src={props.src} />
        </Grid>
      </Dialog>
    </>
  )
}
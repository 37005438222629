/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Badge, Divider, IconButton, Menu } from "@mui/material";
import { Box } from "@mui/system";
import { Text } from "../elements/text";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../elements/button";
import { NOTIFICATION_SUBSCRIPTION } from "../graphql/subscriptions";
import { useSubscription } from "@apollo/client";
import { useSnackbar } from "notistack";
import { NotificationContext } from "../context/notification-context";
import { GET_ALL_NOTIFICATIONS } from "../graphql/query";
import { MARK_AS_SEEN } from "../graphql/mutation";
import { NotificationOut } from "../types/notification.types";
import { AppContext } from "../context/app-context";
import { useNavigate } from "react-router-dom";
import { useQueryHook } from "../hooks/use-query.hook";
import { useMutationHook } from "../hooks/use-mutation.hook";

export const Notification = () => {
  const { adminID } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);

  const { data, fetch: fetchGetAllNotifications } = useQueryHook({
    query: GET_ALL_NOTIFICATIONS,
    variables: {
      payload: {
        filter: {
          subscriberID: adminID?.value,
          seen: false,
        },
        limit: 10,
      },
    },
    skip: adminID?.value ? false : true,
  });

  useSubscription(NOTIFICATION_SUBSCRIPTION, {
    variables: {
      subscriberID: adminID?.value,
    },
    onSubscriptionData: (data: any) => {
      enqueueSnackbar(
        data?.subscriptionData?.data?.onCreateNotification?.message,
        {
          autoHideDuration: 5000,
          variant: "success",
        }
      );

      fetchGetAllNotifications({
        payload: {
          filter: {
            subscriberID: adminID?.value,
            seen: false,
          },
          limit: 10,
        },
      });

      notificationContext.callSubscribers(
        JSON.parse(data?.subscriptionData?.data?.onCreateNotification?.body)
      );
    },
  });

  const {mutate: markAsSeen} = useMutationHook({mutation: MARK_AS_SEEN});

  const notifications = data?.getAllNotifications || [];
  const badgeContent = notifications.length > 9 ? "9+" : notifications.length;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseNotificationHandler = async (notification: NotificationOut) => {
    await markAsSeen({
      variables: {
        ids: [notification._id],
      },
    });

    await fetchGetAllNotifications({
        payload: {
          filter: {
            subscriberID: adminID?.value,
            seen: false,
          },
          limit: 10,
        },
      });
  };

  const clearAllNotificaions = async () => {
    await markAsSeen({
      variables: {
        ids: notifications.map((n: NotificationOut) => n._id),
      },
    });

    await fetchGetAllNotifications({
      payload: {
        filter: {
          subscriberID: adminID?.value,
          seen: false,
        },
        limit: 10,
      },
    });
    handleClose();
  };

  const goToOrderPage = async (notification: NotificationOut) => {
    let body = JSON.parse(notification.body || `{}`);
    if (typeof body == "string" || body instanceof String) {
      body = JSON.parse(body.toString());
    }
    if (notification.type == "ORDER") {
      // TODO: Navigate based on body.status
      navigate('/orders');
      await markAsSeen({
        variables: {
          ids: [notification._id],
        },
      });
      await fetchGetAllNotifications({
        payload: {
          filter: {
            subscriberID: adminID?.value,
            seen: false,
          },
          limit: 10,
        },
      });
    }

    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={badgeContent} color="primary">
          <NotificationsActiveIcon
            color={notifications.length == 0 ? "disabled" : "action"}
          />
        </Badge>
      </IconButton>
      {notifications.length ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          open={open}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box maxHeight={"500px"} width={500}>
            {notifications?.map(
              (notification: NotificationOut, index: number) => {
                return (
                  <Box
                    sx={{
                      ":hover": {
                        background: "#ededed",
                      },
                    }}
                    key={notifications._id + "_" + index}
                  >
                    <Box display={"flex"} p={2}>
                      <Box
                        onClick={() => goToOrderPage(notification)}
                        width={"90%"}
                        mt={1}
                        sx={{ cursor: "pointer", wordBreak: "break-all" }}
                      >
                        <Text
                          fontWeight={700}
                          fontSize={16}
                          text={notification?.message?.toString()}
                        />
                      </Box>
                      <Box sx={{ cursor: "pointer" }}>
                        <IconButton
                          onClick={() =>
                            onCloseNotificationHandler(notification)
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box width="100%">
                      <Divider />
                    </Box>
                  </Box>
                );
              }
            )}
            <Box p={2}>
              <CustomButton title="Clear All" onClick={clearAllNotificaions} />
            </Box>
          </Box>
        </Menu>
      ) : (
        <></>
      )}
    </Box>
  );
};

import React, { cloneElement, useEffect } from "react";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Resource } from "../resource/resource-config";
import { Loader } from "../elements/loader";
import { extractAllErrorMessages } from "../utils/graphql";
import { useQueryHook } from "../hooks/use-query.hook";
import { useMutationHook } from "../hooks/use-mutation.hook";

interface Props {
  resource: Resource;
}

export const ResourceUpdate = ({ resource }: Props) => {
  const { id = "" } = useParams();
  const [showErrSnackbar, setShowErrSnackbar] = React.useState(true);

  const header = `Update ${resource.name}`;
  const navigate = useNavigate();
  const location = useLocation();

  const {
    mutate: updateResource,
    loading: updateLoading,
    error: updateError,
  } = useMutationHook({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mutation: resource.updateMutation!,
    onCompleted: () => {
      navigate(`${resource.path}/list`);
    },
    onError: () => {
      setShowErrSnackbar(true);
    },
  });

  const handleSnackbarClose = () => {
    setShowErrSnackbar(false);
  };

  const {
    data = {},
    loading,
    error,
    fetch: fetchGetOneQuery,
  } = useQueryHook({
    query: resource.getQuery,
    variables: {
      id,
    },
  });

  const errors = extractAllErrorMessages(updateError);

  useEffect(() => {
    fetchGetOneQuery({
      id,
    });
  }, [location]);

  if (loading) {
    return <Loader visible={loading || updateLoading} />;
  }

  return (
    <Box sx={{ maxWidth: "500px", margin: "auto" }}>
      <Box>
        <Typography variant="h4" textAlign={"center"}>
          {header}
        </Typography>
      </Box>
      <Box>
        {error && (
          <Typography color="red" textAlign={"center"}>
            {"Some thing goes wrong..."}
          </Typography>
        )}
        {cloneElement(<resource.form />, {
          submitButtonText: "Update",
          isEdit: true,
          data: data[resource.getQueryName] || {},
          onSubmit: (formData: Resource) => {
            if (resource.key === "refund") {
              updateResource({
                variables: { id, refundStatusInput: formData },
              });
            } else {
              updateResource({
                variables: { id, [resource.key]: formData },
              });
            }
          },
        })}
      </Box>
      <Box>
        <Snackbar
          open={showErrSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Box>
            {errors.map((err, index) => {
              return (
                <Box key={err + index} mb={2}>
                  <Alert variant="filled" severity="error">
                    {err}
                  </Alert>
                </Box>
              );
            })}
          </Box>
        </Snackbar>
      </Box>
    </Box>
  );
};

import { gql } from "@apollo/client";

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription onCreateNotification($subscriberID: String!) {
    onCreateNotification(subscriberID: $subscriberID) {
      subscriberID
      body
      message
    }
  }
`;

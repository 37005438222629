import { patronOrderStatusConfig } from "../constants/constants";
import { OrderManagementTab, Venue, VenueSection } from "../types";
import { PatronOrder } from "../types/order";

const compareLatestOrders = (a: PatronOrder, b: PatronOrder) => {
  if (a.updatedAt < b.updatedAt) {
    return 1;
  }
  if (a.updatedAt > b.updatedAt) {
    return -1;
  }
  return 0;
};

export const sortPatronOrders = (orders: PatronOrder[]): PatronOrder[] => {
  return orders?.slice().sort((a: PatronOrder, b: PatronOrder) => {
    const firstOrderStatus = patronOrderStatusConfig[a.orderStatus]?.order;
    const secondOrderStatus = patronOrderStatusConfig[b.orderStatus]?.order;

    if (firstOrderStatus === secondOrderStatus) {
      return compareLatestOrders(a, b);
    }
    if (firstOrderStatus < secondOrderStatus) {
      return 1;
    }
    if (firstOrderStatus > secondOrderStatus) {
      return -1;
    }
    return 0;
  });
};

export const filterPatronOrders = (
  orders: PatronOrder[],
  tabInfo: OrderManagementTab,
  searchQuery: string
): PatronOrder[] => {
  const validatePatronOrders =
    tabInfo.validatePatronOrders ||
    function () {
      return true;
    };
  const validateSubVendorOrders =
    tabInfo.validateSubVendorOrders ||
    function () {
      return true;
    };
  const filteredOrders: PatronOrder[] = orders.filter((patronOrder) => {
    let isValidPatronOrder = true;
    if (validatePatronOrders(patronOrder)) {
      let hasValidVendorOrder = false;
      patronOrder.vendorOrders.forEach((vendorOrder) => {
        if (validateSubVendorOrders(vendorOrder)) {
          hasValidVendorOrder = hasValidVendorOrder || true;
        }
      });
      isValidPatronOrder = isValidPatronOrder && hasValidVendorOrder;
    } else {
      isValidPatronOrder = false;
    }

    if (isValidPatronOrder) {
      if (searchQuery.trim() != "") {
        if (patronOrder.orderNumber.includes(searchQuery)) {
          return patronOrder;
        }
      }
      else {
        return patronOrder;
      }
    }
  });
  return filteredOrders;
};

export const getStadiumSections = (data: any, venueID: string | undefined) => {
  if (!data || !venueID) return [];
  const venueSections =
    data.getVenues?.find((venue: Venue) => venue.id === venueID)?.sections ||
    [];
  const filteredVenueSections: VenueSection[] = venueSections.filter(
    (section: VenueSection) => !section.isDeleted
  );
  return filteredVenueSections;
};

import {
  Alert,
  AlertColor,
  Box,
  IconButton,
  Pagination,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DenseTable from "../components/table";
import { Resource, RUNNERS_ROUTE } from "../resource/resource-config";
import { Loader } from "../elements/loader";
import { Vendor } from "../types";
import { ConfirmationDailog } from "../elements/confirmation-dialog";
import { CustomButton } from "../elements/button";
import { useQueryHook } from "../hooks/use-query.hook";
import { useMutationHook } from "../hooks/use-mutation.hook";
import { Auth } from "aws-amplify";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

interface Props {
  resource: Resource;
  onEventEmit?: any;
  onRowClick?: any;
}

export const ResourceList = ({ resource, onEventEmit, onRowClick }: Props) => {
  const [data, setData] = useState<Vendor[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();
  const [openModel, setOpenModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Vendor>();
  const defaultOptions = {
    message: "",
    visible: false,
    variant: "success",
  };
  const [showSnackbar, setShowSnackbar] = useState(defaultOptions);
  const [isRefresh, setIsRefresh] = useState(false);

  const { loading, fetch: fetchGetAllQuery } = useQueryHook({
    query: resource.getAllQuery,
    onCompleted: async (data) => {
      const getSignInLink = async (row: { _id: string }) => {
        if (resource.showLoginLink && resource.path === "/vendors") {
          const session = await Auth.currentSession();
          const { attributes } = await Auth.currentAuthenticatedUser();
          return `${
            process.env.REACT_APP_VENDOR_APPLICATION_URL
          }?email=${encodeURIComponent(attributes.email)}&t=${session
            .getAccessToken()
            .getJwtToken()}&vid=${row._id}`;
        } else if (resource.showLoginLink && resource.path === RUNNERS_ROUTE) {
          const session = await Auth.currentSession();
          const { attributes } = await Auth.currentAuthenticatedUser();
          return `${
            process.env.REACT_APP_RUNNER_APPLICATION_URL
          }?email=${encodeURIComponent(attributes.email)}&t=${session
            .getAccessToken()
            .getJwtToken()}&rid=${row._id}`;
        } else {
          return "";
        }
      };
      data[resource.getAllQueryName].forEach(
        async (d: { signInLink: string; _id: string }) => {
          d.signInLink = await getSignInLink(d);
        }
      );
      setData(data[resource.getAllQueryName]);
      if (isRefresh) {
        setShowSnackbar({
          message: "Successfully Refreshed",
          visible: true,
          variant: "success",
        });
      }
    },
  });
  const { mutate: deleteResource, loading: deleteRequestLoading } =
    useMutationHook({
      mutation: resource.deleteMutation,
      onCompleted: () => fetchGetAllQuery(),
    });

  const itemsPerPage = 15;
  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;

  const sortedData = [...data].sort((a, b) => {
    if (a.isDisabled === false && b.isDisabled === true) {
      return -1;
    } else if (a.isDisabled === true && b.isDisabled === false) {
      return 1;
    } else {
      if (a.name && b.name) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      } else {
        return a.tradingName
          .toLowerCase()
          .localeCompare(b.tradingName.toLowerCase());
      }
    }
  });

  const currentItems = sortedData.slice(indexOfFirstRecord, indexOfLastRecord);
  const pages = Math.ceil(data.length / itemsPerPage);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    fetchGetAllQuery();
  }, [location, currentPage]);
  return (
    <Box m={4} mt={2}>
      <Snackbar
        open={showSnackbar.visible}
        autoHideDuration={3000}
        onClose={() => {
          setShowSnackbar(defaultOptions);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box minWidth={300}>
          <Alert variant="filled" severity={showSnackbar.variant as AlertColor}>
            {showSnackbar.message}
          </Alert>
        </Box>
      </Snackbar>
      <Loader visible={loading || deleteRequestLoading} />
      <ConfirmationDailog
        title={`Delete ${resource.name}?`}
        text="This action cannot be undone. Do you want to continue?"
        open={openModel}
        handleContinue={() => {
          if (selectedItem) {
            deleteResource({ variables: { id: selectedItem._id } });
            fetchGetAllQuery();
          }
          setOpenModel(false);
        }}
        handleCancel={() => setOpenModel(false)}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
        mb={2}
      >
        <Typography sx={{ flexGrow: 1 }} variant="h4" noWrap component="div">
          {`${resource.name}s`}
        </Typography>
        {resource.createMutation && (
          <Box sx={{ float: "right" }}>
            <CustomButton
              onClick={() => navigate(resource.path + "/create")}
              title={"Create"}
            />
          </Box>
        )}
        {resource.showRefresh && (
          <IconButton
            onClick={() => {
              setIsRefresh(true);
              fetchGetAllQuery();
            }}
            style={{ borderRadius: 0 }}
            sx={{
              justifyContent: "end",
            }}
          >
            <RefreshRoundedIcon
              sx={{
                fontSize: "36px",
                color: "#2A2A2A",
                cursor: "pointer",
                padding: "2px",
                borderRadius: "6px",
                border: "2px solid #DDE0E4",
                backgroundColor: "rgba(255, 255, 255, 1)",
                boxShadow: 1,
              }}
            />
          </IconButton>
        )}
      </Box>
      <Box>
        <DenseTable
          onEditClick={(item) => {
            navigate(`${resource.path}/update/${item._id || item.id}`);
          }}
          onDeleteClick={(item) => {
            setSelectedItem(item);
            setOpenModel(true);
          }}
          onEventEmit={onEventEmit}
          data={currentItems}
          config={resource.tableConfig}
          isDuplicable={resource.isDuplicable}
          showLoginLink={resource.showLoginLink}
          isEditable={resource.isEditable}
          onRowClick={onRowClick}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={2}
        >
          <Pagination count={pages} onChange={handleChange} />
        </Box>
      </Box>
    </Box>
  );
};

import moment from "moment";
import React from "react";
import { createContext, ReactElement } from "react";
import { IContextValue, useIContext } from "./common";

interface IDateFilter {
  start: string;
  end: string;
}

type OrderContextType = {
  startTime?: IContextValue<string>;
  startTimeUnit?: IContextValue<string>;
  vendorIdFilter?: IContextValue<string[]>
  getFilter: () => IDateFilter;
};

export const OrderContext = createContext<OrderContextType>({
  getFilter: () => ({ start: "", end: "", vendorIDs: [] }),
});

interface Props {
  children: ReactElement;
}

export const DEFAULT_START_VALUE = "2";
export const DEFAULT_START_UNIT = "hours";

function OrderContextProvider({ children }: Props) {
  const startTime = useIContext<string>(DEFAULT_START_VALUE);
  const startTimeUnit = useIContext<string>(DEFAULT_START_UNIT);
  const vendorIdFilter = useIContext<string[]>([]);

  const getFilter = () => {
    return {
      start: moment()
        .subtract(parseInt(startTime.value ?? "0"), startTimeUnit?.value as any)
        .toString(),
      end: moment(Date.now()).toString(),
    };
  };

  const value = {
    startTime,
    startTimeUnit,
    getFilter,
    vendorIdFilter
  };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}

export default OrderContextProvider;

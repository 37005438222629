import React from "react";
import { Grid } from "@mui/material";
import { useState } from "react";
import { CustomButton } from "../elements/button";
import { CustomInput } from "../elements/input";
import { Admin } from "../types";

interface Props {
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (data: Admin) => void;
  data?: Admin;
  isEdit: boolean;
  submitButtonText?: string;
}

export const AdminForm = (props: Props) => {
  const [name, setName] = useState(props.data?.name);
  const [email, setEmail] = useState(props.data?.email);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { onSubmit = () => {} } = props;
    onSubmit({
      name: name || "",
      email: email || "",
    });
  };

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput required onChange={setName} title="Name" value={name} />
        </Grid>

        <Grid item xs={12}>
          <CustomInput
            required
            onChange={setEmail}
            disabled={props.isEdit}
            title="Email"
            value={email}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomButton title={props.submitButtonText || "Create"} />
        </Grid>
      </Grid>
    </form>
  );
};

/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import {
  ApolloError,
  DocumentNode,
  useLazyQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { isTokenExpired } from "../utils/refresh-token";

interface Props {
  query: DocumentNode;
  variables?: object;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  fetchPolicy?: WatchQueryFetchPolicy;
  onError?: (e: any) => void;
  onCompleted?: (data: any) => void;
  skipInitialCall?: boolean;
  skip?: any;
}

interface UseQueryHookResponse {
  data: any;
  loading: boolean;
  client?: any;
  error?: ApolloError;
  fetch?: any;
}

export const useQueryHook = ({
  query,
  variables,
  skipInitialCall = false,
  onError = () => {},
  onCompleted = () => {},
  skip,
}: Props): UseQueryHookResponse => {
  const [fetch, { data, loading, error, client }] = useLazyQuery(query, {
    onError: (e) => {
      console.log(e);
      onError(e);
    },
    onCompleted: (data) => {
      onCompleted(data);
    },
  });

  useEffect(() => {
    if (!skipInitialCall) {
      makeFetchQuery(variables);
    }
  }, []);

  const makeFetchQuery = async (variables: any) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.getSignInUserSession()?.getAccessToken()?.getJwtToken();

    if (skip) {
      return;
    }
    if (isTokenExpired(token)) {
      console.log(`Refreshing the token`);
      return makeFetchQueryWithRefreshToken(variables);
    }
    return fetch({
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
      variables,
      context: {
        headers: {
          authorization: token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      },
    });
  };

  const makeFetchQueryWithRefreshToken = async (variables: any) => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const newToken = user?.signInUserSession?.accessToken?.jwtToken;
    return fetch({
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
      variables,
      context: {
        headers: {
          authorization: newToken,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      },
    });
  };

  return {
    data,
    loading,
    error,
    client,
    fetch: async (variables: any) => {
      return makeFetchQuery(variables);
    },
  };
};

import { Box, Avatar, Grid } from "@mui/material"
import { FormToolTip } from "../../elements/form-tooltip"
import { Text } from "../../elements/text"
import { UploadType } from "../../types/file"
import ImageUpload from "../Image-upload/image-upload"
import { ViewImageModal } from "../Image-upload/view-image-modal"
import UploadIcon from "../../assets/images/image-upload.jpeg"

interface IVenueImageCard {
	previewImageSrc?: string;
	label: string;
	toolTipLabel: string;
	isMandatory?: boolean;
	resourceImageSrc?: string;
	defaultImageSrc?: string;
	onUploadImage: any;
	venueName?: string;
	resourceImageNote: string;
	imageAspectRatio?: number;
}

export const VenueImageCard = (props: IVenueImageCard) => {
	return (
		<Grid item xs={12} borderRadius={'8px'} sx={{ backgroundColor: 'white' }} mt={2} p={'16px'}>
			<Box display={'flex'} justifyContent={'space-between'}>
				<Box position={'relative'} width={'100%'}>
					<Box sx={{ display: 'flex', flexDirection:"row" }}>
						<Text
							text={props.label}
							fontSize={18}
							color={"#222"}
						/>
						{props.isMandatory === true && < Text fontSize={17} color="red" text="*" />}
						<FormToolTip text={props.toolTipLabel} />
					</Box>
					<Box>
						{!props.resourceImageSrc && <Text text="(Currently using default image)" color={'#afafaf'} fontSize={14} />}
					</Box>
					<Box position={'absolute'} bottom={0}>
						<ViewImageModal
							src={props.previewImageSrc}
							buttonTitle={"Click for Preview"}
							style={{
								fontSize: '12px',
								letterSpacing: '0.75px',
								textDecoration: 'underline',
								padding: 0,
								marginTop: '2px',
							}}
						/>
					</Box>
				</Box>
				<Box alignItems={'start'}>
					<Avatar alt="Image" src={props.resourceImageSrc || UploadIcon} sx={{ width: '110px', height: '110px', borderRadius: 0 }} />
				</Box>
			</Box>
			<Box mt={1}>
				<ImageUpload
					onUpload={props.onUploadImage}
					src={props.resourceImageSrc}
					imageType={UploadType.VENUE}
					name={props.venueName?.replace(/\s/g, "")}
					imageNote={props.resourceImageNote}
					imageTitle={props.label}
					aspectRatio={props.imageAspectRatio}
				/>
			</Box>
		</Grid>
	)
}
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { vendorOrderStatusConfig } from "../constants/constants";
import { CustomSelect, MenuItemType } from "../elements/select";
import { AssignableRunner, OrderStatus } from "../types";
import { Text } from "../elements/text";
import { VendorOrder } from "../types/order";

type VendorOrderCardProps = {
  order: VendorOrder;
  runners: AssignableRunner[];
  refetchRunner: any;
  isRunnerAssignedAtPatonOrder: boolean;
  // eslint-disable-next-line no-unused-vars
  onRunnerAssign: (orderID: string, runnerID?: string) => void;
};

const VendorOrderCard: React.FC<VendorOrderCardProps> = ({
  order,
  runners,
  isRunnerAssignedAtPatonOrder,
  onRunnerAssign,
}) => {
  const [runnerID, setRunnerID] = useState<string>("");

  const reAssignableRunners = runners.filter(
    (runner) => runner.runner?._id !== order.runner?._id
  );

  const getRunnerSelectData = (runners: AssignableRunner[]) => {
    return runners?.map((assignableRunner: AssignableRunner) => {
      const menuItem: MenuItemType = {
        value: assignableRunner.runner._id as string,
        label: `${assignableRunner.runner.name} (${assignableRunner.count})`,
      };
      return menuItem;
    });
  };

  const runner = order?.runner;

  const onRunnerAssignHandler = (runnerID?: string) => {
    onRunnerAssign(order._id, runnerID);
    setRunnerID(runnerID ?? "");
  };

  return (
    <>
      <Box
        sx={{
          my: 1,
          p: 1,
          borderRadius: 2,
          border: "3px solid #D1C7C7",
          display: "flex",
        }}
      >
        <Box>
          <Typography
            color={vendorOrderStatusConfig[order.orderStatus].color}
            fontSize={12}
            fontWeight={500}
          >
            {vendorOrderStatusConfig[order.orderStatus].text}
          </Typography>
          <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
            <Text
              text={`Order #${order.orderNumber || ""}`}
              fontSize={14}
              fontWeight={600}
              color={"#300304"}
            />
            <Box
              sx={{
                p: 0.5,
                m: 0.5,
                mr: 2,
                borderRadius: 1,
              }}
            >
              {order?.isOrderUpdated && (
                <Text fontWeight={700} text="(E)" color={"#950305"} />
              )}
            </Box>
          </Box>

          <Typography fontSize={14} fontWeight={500} color={"#300304"}>
            {order?.vendor?.tradingName}
          </Typography>
        </Box>
        {isRunnerAssignedAtPatonOrder || (
          <Box sx={{ flex: 1, alignItems: "center", display: "flex" }}>
            {order.orderStatus === OrderStatus.PREPARED &&
            getRunnerSelectData(runners).length > 0 ? (
              <CustomSelect
                required
                data={getRunnerSelectData(runners)}
                onChange={onRunnerAssignHandler}
                floatingLabel
                title="Assign runner"
                value={runnerID}
              />
            ) : (
              runner && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography fontSize={14} fontWeight={500}>
                    Runner Details
                  </Typography>
                  <Typography fontSize={14} fontWeight={600}>
                    {runner?.name}
                  </Typography>
                  <Typography fontSize={14} fontWeight={500}>
                    {runner?.phoneNumber}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        )}

        <Box display={"flex"} flex={1}>
          {order.orderStatus === OrderStatus.ASSIGNED &&
            !isRunnerAssignedAtPatonOrder &&
            getRunnerSelectData(reAssignableRunners).length > 0 && (
              <CustomSelect
                required
                data={getRunnerSelectData(reAssignableRunners)}
                onChange={onRunnerAssignHandler}
                floatingLabel
                title="Re-Assign runner"
                value={runnerID}
              />
            )}
        </Box>
      </Box>
    </>
  );
};

export default VendorOrderCard;

import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Footer,
  Header,
  SignInFooter,
  SignInHeader,
} from "./amplify/amplify-custom-elements";
import { Wizard } from "./wizard";
import OrdersContextProvider from "./context/order-context";
import { CognitoUserAmplify } from "@aws-amplify/ui-react/node_modules/@aws-amplify/ui";
import "@aws-amplify/ui-react/styles.css";
import config from "./config";
import NotificationContextProvider from "./context/notification-context";
import { SnackbarProvider } from "notistack";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import AppContextProvider from "./context/app-context";

const components = {
  Header: () => <Header />,
  Footer: () => <Footer />,
  SignIn: {
    Header: () => <SignInHeader title="Admin Login" />,
    Footer: () => <SignInFooter />,
  },
};

function prepareGraphqlClient(user: CognitoUserAmplify | undefined) {
  const token = user?.getSignInUserSession()?.getAccessToken().getJwtToken();

  const graphqlURL = config.graphql;
  const httpLink = createHttpLink({
    uri: graphqlURL,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const wsLink = createSubscriptionHandshakeLink({
    url: config.subscriptionURL,
    region: config.region,
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS",
      jwtToken: async () => {
        return token!;
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink as any,
    authLink.concat(httpLink)
  );

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
}

function App() {
  return (
    <Authenticator
      hideSignUp={true}
      components={components}
      className="amplify"
    >
      {({ user }) => (
        <ApolloProvider client={prepareGraphqlClient(user)}>
          <AppContextProvider>
            <OrdersContextProvider>
              <NotificationContextProvider>
                <SnackbarProvider maxSnack={3}>
                  <Wizard />
                </SnackbarProvider>
              </NotificationContextProvider>
            </OrdersContextProvider>
          </AppContextProvider>
        </ApolloProvider>
      )}
    </Authenticator>
  );
}

export default App;

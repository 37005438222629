import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BackpackIcon from "@mui/icons-material/Backpack";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

import {
  OrderStatus,
  OrderStatusConfig,
  PatronOrderStatus,
  ReportFilterConfig,
  ReportFilterType,
  OrderManagementTab,
  VenueTypeConfig,
  VenueType,
} from "../types";
import { GET_RUNNERS, GET_VENDORS, GET_VENUES } from "../graphql/query";
import { CheckCircle } from "@mui/icons-material";
import { ServiceMethod } from "../types/order";

export const runnerEnabledServiceMethods = [
  ServiceMethod.COLLECTION_POINT,
  ServiceMethod.DELIVERY,
];

export const ordersTabConfig: OrderManagementTab[] = [
  {
    index: 0,
    title: "All",
    icon: PendingActionsIcon,
  },
  {
    index: 1,
    title: "Waiting For Acceptance",
    icon: FactCheckIcon,
    patronOrderStatus: [PatronOrderStatus.RECEIVED],
  },
  {
    index: 2,
    title: "Ready For Patron Collection",
    icon: BackpackIcon,
    patronOrderStatus: [
      PatronOrderStatus.READY_TO_COLLECT,
      PatronOrderStatus.PARTIALLY_READY_TO_COLLECT,
    ],
    validatePatronOrders: (patronOrder) => {
      return (
        patronOrder.serviceMethod === ServiceMethod.COLLECT_FROM_VENDOR ||
        patronOrder.serviceMethod === ServiceMethod.COLLECTION_POINT
      );
    },
  },
  {
    index: 3,
    title: "Runner Not Assigned",
    icon: AssignmentIcon,
    patronOrderStatus: [
      PatronOrderStatus.PREPARED,
      PatronOrderStatus.PARTIALLY_ASSIGNED,
    ],
    validatePatronOrders: (patronOrder) => {
      const isRunnerEnabledOrder = runnerEnabledServiceMethods.includes(
        patronOrder.serviceMethod
      );
      return isRunnerEnabledOrder;
    },
  },
  {
    index: 4,
    title: "Assigned/Waiting For Pickup",
    icon: AssignmentTurnedInIcon,
    patronOrderStatus: [PatronOrderStatus.ASSIGNED],
    validatePatronOrders: (patronOrder) => {
      const hasUnpickedOrder = patronOrder.vendorOrders.some(
        (vendorOrder) => vendorOrder.orderStatus === OrderStatus.ASSIGNED
      );
      const isRunnerEnabledOrder = runnerEnabledServiceMethods.includes(
        patronOrder.serviceMethod
      );
      return isRunnerEnabledOrder && hasUnpickedOrder;
    },
  },
  {
    index: 5,
    title: "Out For Delivery",
    icon: DirectionsRunIcon,
    patronOrderStatus: [
      PatronOrderStatus.PICKED
    ],
    validatePatronOrders: (patronOrder) => {
      const isRunnerEnabledOrder = runnerEnabledServiceMethods.includes(
        patronOrder.serviceMethod
      );
      return isRunnerEnabledOrder;
    },
    validateSubVendorOrders: (vendorOrder) => {
      return vendorOrder.orderStatus === OrderStatus.PICKED;
    },
  },
  {
    index: 6,
    title: "Completed",
    icon: CheckCircle,
    patronOrderStatus: [PatronOrderStatus.DELIVERED, PatronOrderStatus.REJECTED, PatronOrderStatus.PAYMENT_REJECTED],
  },
];

export const patronOrderStatusConfig: OrderStatusConfig = {
  [PatronOrderStatus.RECEIVED]: {
    text: "Received",
    backgroundColor: "#FFFAEB",
    textColor: "#B54708",
    canAssignRunner: false,
    order: 1,
  },
  [PatronOrderStatus.PAYMENT_PENDING]: {
    text: "Payment Pending",
    backgroundColor: "#FFFAEB",
    textColor: "#B54708",
    canAssignRunner: false,
    order: 2,
  },
  [PatronOrderStatus.PREPARING]: {
    text: "Preparing",
    backgroundColor: "yellow",
    textColor: "#027A48",
    canAssignRunner: true,
    order: 3,
  },
  [PatronOrderStatus.READY_TO_COLLECT]: {
    text: "Ready To Collect",
    backgroundColor: "#F6FFD6",
    textColor: "#2A2A2A",
    canAssignRunner: false,
    order: 3,
  },
  [PatronOrderStatus.PARTIALLY_READY_TO_COLLECT]: {
    text: "Partially Ready To Collect",
    backgroundColor: "#F6FFD6",
    textColor: "#2A2A2A",
    canAssignRunner: false,
    order: 3,
  },
  [PatronOrderStatus.PREPARED]: {
    text: "Prepared",
    backgroundColor: "#ECFDF3",
    textColor: "#027A48",
    canAssignRunner: true,
    order: 4,
  },
  [PatronOrderStatus.PARTIALLY_ASSIGNED]: {
    text: "Partially Assigned",
    backgroundColor: "#FFFAEB",
    textColor: "#B54708",
    canAssignRunner: true,
    order: 5,
  },
  [PatronOrderStatus.ASSIGNED]: {
    text: "Assigned",
    backgroundColor: "#FFFAEB",
    textColor: "#B54708",
    canAssignRunner: true,
    order: 6,
  },
  [PatronOrderStatus.PICKED]: {
    text: "Picked",
    backgroundColor: "#FFFAEB",
    textColor: "#B54708",
    canAssignRunner: false,
    order: 6,
  },
  [PatronOrderStatus.PARTIALLY_PICKED]: {
    text: "Partially Picked",
    backgroundColor: "#FFFAEB",
    textColor: "#B54708",
    canAssignRunner: false,
    order: 6,
  },
  [PatronOrderStatus.DELIVERED]: {
    text: "Delivered",
    backgroundColor: "#ECFDF3",
    textColor: "#027A48",
    canAssignRunner: false,
    order: 8,
  },
  [PatronOrderStatus.REJECTED]: {
    text: "Rejected",
    backgroundColor: "#fc8d66",
    textColor: "#202020",
    canAssignRunner: false,
    order: 7,
  },
  [PatronOrderStatus.PAYMENT_REJECTED]: {
    text: "Payment Rejected",
    backgroundColor: "#fc8d66",
    textColor: "#202020",
    canAssignRunner: false,
    order: 7,
  },
  [PatronOrderStatus.PATRON_CANCELLED]: {
    text: "Patron Cancelled",
    backgroundColor: "#fc8d66",
    textColor: "#202020",
    canAssignRunner: false,
    order: 1,
  },
  [PatronOrderStatus.ADMIN_CANCELLED]: {
    text: "Admin Cancelled",
    backgroundColor: "#fc8d66",
    textColor: "#202020",
    canAssignRunner: false,
    order: 2,
  },
};

export const vendorOrderStatusConfig = {
  [OrderStatus.RECEIVED]: {
    text: "Received",
    color: "#b54708",
  },
  [OrderStatus.PAYMENT_PENDING]: {
    text: "Payment Pending",
    color: "#b54708",
  },
  [OrderStatus.ACCEPTED]: {
    text: "Accepted",
    color: "#344054",
  },
  [OrderStatus.PREPARING]: {
    text: "Preparing",
    color: "yellow",
  },
  [OrderStatus.PREPARED]: {
    text: "Prepared",
    color: "#67CE67",
    canAssignRunner: true,
  },
  [OrderStatus.ASSIGNED]: {
    text: "Assigned",
    color: "#67CE67",
  },
  [OrderStatus.PICKED]: {
    text: "Picked",
    color: "#67CE67",
  },
  [OrderStatus.AT_COLLECTION_POINT]: {
    text: "At Collection Point",
    color: "#67CE67",
  },
  [OrderStatus.COMPLETE]: {
    text: "Completed",
    color: "#027a48",
  },
  [OrderStatus.REJECTED]: {
    text: "Rejected",
    color: "#fc8d66",
  },
  [OrderStatus.PAYMENT_REJECTED]: {
    text: "Payment Rejected",
    color: "#fc8d66",
  },
  [OrderStatus.PATRON_CANCELLED]: {
    text: "Rejected",
    color: "#fc8d66",
  },
  [OrderStatus.ADMIN_CANCELLED]: {
    text: "Rejected",
    color: "#fc8d66",
  },
};

export const reportFilterConfig: ReportFilterConfig = {
  [ReportFilterType.ADMIN]: {
    label: "Admin",
    filterValueQuery: GET_VENUES,
    filterValueQueryName: "getVenues",
    showOptionsBy: "name",
  },
  [ReportFilterType.VENDOR]: {
    label: "Vendor",
    filterValueQuery: GET_VENDORS,
    filterValueQueryName: "getVendors",
    showOptionsBy: "tradingName",
  },
  [ReportFilterType.RUNNER]: {
    label: "Runner",
    filterValueQuery: GET_RUNNERS,
    filterValueQueryName: "getRunners",
    showOptionsBy: "name",
  },
  [ReportFilterType.VENDOR_ORDER_STATUS]: {
    label: "Vendor Order Status",
    filterValueQuery: GET_VENDORS,
    filterValueQueryName: "getVendors",
    showOptionsBy: "tradingName",
  },
  //commented to remove access but keep functionality for future
  // [ReportFilterType.RUNNER_EARNINGS]: {
  //   label: "Runner Earnings",
  //   filterValueQuery: GET_RUNNERS,
  //   filterValueQueryName: "getRunners",
  //   showOptionsBy: "name",
  // },
};

export const validServiceMethods = [
  ServiceMethod.COLLECTION_POINT,
  ServiceMethod.COLLECT_FROM_VENDOR,
  ServiceMethod.DELIVERY,
  ServiceMethod.VENDOR_DELIVERY,
];

export const serviceMethodsTitleMap: Record<string, string> = {
  DELIVERY: "Delivery",
  COLLECTION_POINT: "Collection Point",
  COLLECT_FROM_VENDOR: "Collect From Vendor",
};

export const dineInServiceMethodsTitleMap: Record<string, string> = {
  DELIVERY: "Delivery",
  COLLECTION_POINT: "Collection Point",
  COLLECT_FROM_VENDOR: "Collect From Vendor",
  VENDOR_DELIVERY: "Vendor Delivery"
};

export const venueTypeConfig: VenueTypeConfig[] = [
  {
    name: VenueType.DINE_IN,
    label: 'Dine In'
  },
  {
    name: VenueType.EVENT,
    label: 'Event or Stadium'
  },
  {
    name: VenueType.HOTEL,
    label: 'Hotel'
  }
]
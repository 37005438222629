import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Auth } from "aws-amplify";
import { CustomButton } from "../elements/button";
import { Text } from "../elements/text";

export const UnAuthorized = () => {
  return (
    <Grid
      p={2}
      item
      container
      xs={12}
      sm={6}
      md={4}
      m="auto"
      rowGap={2}
      mt={10}
    >
      <Grid xs={12} item>
        <Box textAlign={"center"}>
          <Text
            text="You are not authorized yet, please contact admin"
            fontWeight={700}
            fontSize={16}
          />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <CustomButton title="Signout" onClick={() => Auth.signOut()} />
      </Grid>
    </Grid>
  );
};

import * as React from "react";
import { Box, Snackbar, Typography } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { cloneElement } from "react";
import { useNavigate } from "react-router-dom";
import { Resource } from "../resource/resource-config";
import { Loader } from "../elements/loader";
import { extractAllErrorMessages } from "../utils/graphql";
import { useMutationHook } from "../hooks/use-mutation.hook";

interface Props {
  resource: Resource;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ResourceCreate = ({ resource }: Props) => {
  const [showErrSnackbar, setShowErrSnackbar] = React.useState(true);
  const navigate = useNavigate();

  let isDuplicate = false;
  const fetchDuplicateData = localStorage.getItem("duplicateData");
  const duplicateData = fetchDuplicateData
    ? JSON.parse(fetchDuplicateData)
    : null;
  isDuplicate = duplicateData?.resource == resource.name.toLowerCase();
  const {
    mutate: createResource,
    loading,
    error,
  } = useMutationHook({
    mutation: !isDuplicate
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resource.createMutation!
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resource.duplicateResource!,
    onCompleted: () => {
      navigate(`${resource.path}/list`);
    },
    onError: () => {
      setShowErrSnackbar(true);
    },
  });

  const header = isDuplicate
    ? `Duplicate ${resource.name}`
    : `Create ${resource.name}`;

  const errors = extractAllErrorMessages(error);

  const handleSnackbarClose = () => {
    setShowErrSnackbar(false);
  };

  return (
    <Box sx={{ maxWidth: "500px", margin: "auto" }}>
      <Loader visible={loading} />
      <Box>
        <Typography variant="h4" textAlign={"center"}>
          {header}
        </Typography>
      </Box>
      <Box>
        {cloneElement(<resource.form duplicateData={duplicateData} />, {
          onSubmit: (data: Resource) => {
            createResource(
              isDuplicate
                ? {
                  variables: {
                    originalVendorId: duplicateData.data._id,
                    [resource.key]: data,
                  },
                }
                : {
                  variables: {
                    [resource.key]: data,
                  },
                }
            );
          },
        })}
      </Box>
      <Box>
        <Snackbar
          open={showErrSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Box>
            {errors.map((err, index) => {
              return (
                <Box key={err + index} mb={2}>
                  <Alert variant="filled" severity="error">
                    {err}
                  </Alert>
                </Box>
              );
            })}
          </Box>
        </Snackbar>
      </Box>
    </Box>
  );
};

import React from "react";
import {
  Button,
  Heading,
  Text,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { Image } from "../elements/image";
import logo from "../assets/images/logo.png";
import { Box } from "@mui/material";
import "./amplify.css";
interface SignInHeaderProps {
  title?: string;
}

export const SignInHeader = ({ title = "Login" }: SignInHeaderProps) => {
  return (
    <View>
      <Box p={2}>
        <Heading level={3}>{title}</Heading>
      </Box>
    </View>
  );
};

export const SignInFooter = () => {
  const { toResetPassword } = useAuthenticator();
  return (
    <View textAlign="center">
      <Box p={2}>
        <Button
          fontWeight="normal"
          onClick={toResetPassword}
          size="small"
          variation="link"
        >
          Reset Password
        </Button>
      </Box>
    </View>
  );
};

export const Header = () => (
  <Box width={200} margin="auto" mb={2}>
    <Image src={logo} />
  </Box>
);

export const Footer = () => {
  return (
    <Box textAlign="center" mt={2}>
      <Text>&copy; All Rights Reserved: Liively</Text>
    </Box>
  );
};

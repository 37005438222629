import { DocumentNode } from "graphql";
import { PatronOrder, VendorOrder } from "./order";
import { DeliveryAreas } from '../components/venue-form/delivery-areas';

/* eslint-disable no-unused-vars */
export type Charges = {
  markupCharge: number;
  seatDeliveryCharge: number;
  collectionPointDeliveryCharge: number;
  serviceCharge: number;
};

export type RefundStatusForm = {
  id: string;
  refundStatus: string;
  amountRefunded?: number;
  closeDate?: Date;
  transactionId?: string;
  issueDescription: {
    refundReason: string;
    reportorComments: string[];
    reviewerComments: string[];
  };
};

export enum UserType {
  // eslint-disable-next-line no-unused-vars
  admin = "admin",
}

export type Runner = {
  _id?: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  tfn: string;
  bankAccount: string;
  superAnnuationFund: string;
  BSB: string;
  venueID: string;
  sectionIDs: string[];
  isDisabled: boolean;
};

export type Vendor = {
  _id?: string;
  businessName: string;
  tradingName: string;
  imageUrl?: string;
  abn: string;
  bankAccount: string;
  BSB: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  hoursOfOperation: string;
  isDisabled: boolean;
  stallID: string;
  venueID?: string;
  sectionIDs?: string[];
  name?: string;
};

export type VenueSection = {
  label: string;
  id: string;
  isClickAndCollect: boolean;
  cncNavigationImageUrl?: string;
  isDeleted?: boolean;
  serviceMethods: string[];
};

export type Admin = {
  _id?: string;
  name: string;
  email: string;
  cognitoID?: string;
};

export type Currency = {
  _id: string;
  name: string;
  symbol: string;
  stripeCode: string;
};

export type CollectionPoint = {
  id: string;
  label: string;
  serviceableSectionsIds?: string[];
  cpNavigationImageUrl?: string;
  isDeleted?: boolean;
};

export type DeliveryArea = {
  id: string;
  label: string; 
  serviceableSectionsIds?: string[];
  daNavigationImageUrl?: string;
  isDeleted?: boolean;
}

export enum VenueType {
  DINE_IN = "DINE_IN",
  EVENT = "EVENT",
  HOTEL = "HOTEL",
}

export type Venue = {
  /**
   * @deprecated
   */
  _id?: string; // todo: to be removed
  id?: string;
  name: string;
  venueCode: string;
  sections: VenueSection[];
  collectionPoints: CollectionPoint[];
  deliveryAreas: DeliveryArea[];
  address?: {
    country: string; // Country Code?
    state: string;
    addressLine1: string;
    addressLine2: string;
    postlCode: string;
  };
  venueMapImageUrl?: string;
  ttsBrandingImageUrl?: string;
  cncBrandingImageUrl?: string;
  vendorListImageUrl?: string;
  printReceiptImageUrl?:string;
  patronNavigationImageUrl?: string;
  venueType: string;
  isDisabled: boolean;
  venueCurrency: Currency; // todo:
  minimumOrderPrice: number;
  fixedServiceCharge: number;
  fixedVendorDeliveryCharge: number
  accountID: string;
};

export type VenueMutation = Venue & {
  capturePaymentByOrderStatus: string;
};

export type VenueQuery = Venue & {
  orderSettings: {
    capturePaymentByOrderStatus: string;
  };
};

export type VenueTypeConfig = {
  name: VenueType;
  label: string;
};

export interface Location {
  venue: string;
  section: string;
  seat: string;
  row: string;
}

export enum OrderStatus {
  RECEIVED = "RECEIVED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PREPARING = "PREPARING",
  PREPARED = "PREPARED",
  ASSIGNED = "ASSIGNED",
  PICKED = "PICKED",
  COMPLETE = "COMPLETE",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_REJECTED = "PAYMENT_REJECTED",
  ADMIN_CANCELLED = "ADMIN_CANCELLED",
  PATRON_CANCELLED = "PATRON_CANCELLED",
  AT_COLLECTION_POINT = "AT_COLLECTION_POINT",
}

export enum PatronOrderStatus {
  RECEIVED = "RECEIVED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_REJECTED = "PAYMENT_REJECTED",
  PARTIALLY_ASSIGNED = "PARTIALLY_ASSIGNED",
  PREPARING = "PREPARING",
  PREPARED = "PREPARED",
  READY_TO_COLLECT = "READY_TO_COLLECT",
  PARTIALLY_READY_TO_COLLECT = "PARTIALLY_READY_TO_COLLECT",
  ASSIGNED = "ASSIGNED",
  PICKED = "PICKED",
  PARTIALLY_PICKED = "PARTIALLY_PICKED",
  DELIVERED = "DELIVERED",
  REJECTED = "REJECTED",
  PATRON_CANCELLED = "PATRON_CANCELLED",
  ADMIN_CANCELLED = "ADMIN_CANCELLED",
}

export enum ReportFilterType {
  ADMIN = "ADMIN",
  VENDOR = "VENDOR",
  RUNNER = "RUNNER",
  VENUE = "VENUE",
  VENDOR_ORDER_STATUS = "VENDOR_ORDER_STATUS",
  RUNNER_EARNINGS = "RUNNER_EARNINGS",
}

export enum RefundStatus {
  RAISED = "RAISED",
  PROCESSING = "PROCESSING",
  DENIED = "DENIED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  REFUNDED = "REFUNDED",
}

export enum TerminatingRefundStatus {
  DENIED = "DENIED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  REFUNDED = "REFUNDED",
}

export interface OrderedItems {
  itemID: string;
  count: number;
  customisations: string[];
}

export type OptionWithType = {
  _id: string;
  name: string;
};

export interface PatronList {
  orders: PatronOrder[];
}

export type Patron = {
  name: string;
  phoneNumber: string;
};

export type VendorOrderAssignmentInfo = {
  orderID: string;
  runnerID: string;
};

export type OrderManagementTab = {
  index: number;
  title: string;
  patronOrderStatus?: PatronOrderStatus[];
  vendorOrderStatus?: OrderStatus[];
  icon: any;
  validatePatronOrders?: (patronOrder: PatronOrder) => unknown; //checks field in all patron orders
  validateSubVendorOrders?: (vendorOrder: VendorOrder) => unknown; //checks filed in all vendor orders within a patron order
  count?: number;
};

export type OrderStatusConfig = {
  [key: string]: {
    text: string;
    backgroundColor: string;
    textColor: string;
    canAssignRunner: boolean;
    order: number;
  };
};

export type Item = {
  _id?: string;
  imageUrl: string;
  price: number;
  name: string;
  description: string;
  outOfStock: boolean;
  showInMenu: boolean;
  vendorID: string;
};

export type AssignableRunner = {
  runner: Runner;
  count: number;
};

export type ReportFilterConfig = {
  [key: string] : {
    label: string;
    filterValueQuery: DocumentNode;
    filterValueQueryName: string;
    showOptionsBy: string;
  }
}
import { DocumentNode } from "graphql";
import { TableConfig } from "../components/table";
import { AdminForm } from "../forms/admin-form";
import { RunnerForm } from "../forms/runner-form";
import { VendorForm } from "../forms/vendor-form";
import { VenueForm } from "../forms/venue-form";
import {
  CREATE_ADMIN,
  CREATE_RUNNER,
  CREATE_VENDOR,
  CREATE_VENUE,
  DELETE_ADMIN,
  DELETE_REFUND,
  DELETE_RUNNER,
  DELETE_VENDOR,
  DELETE_VENUE,
  DUPLICATE_VENDOR,
  UPDATE_ADMIN,
  UPDATE_REFUND,
  UPDATE_RUNNER,
  UPDATE_VENDOR,
  UPDATE_VENUE,
} from "../graphql/mutation";
import {
  GET_VENUE,
  GET_VENDOR,
  GET_RUNNER,
  GET_VENDORS,
  GET_VENUES,
  GET_RUNNERS,
  GET_ADMINS,
  GET_ADMIN,
  GET_REFUND_BY_ID,
  GET_REFUND_ORDERS,
} from "../graphql/query";
import {
  AdminTableConfig,
  OrderRefundTableConfig,
  RunnerTableConfig,
  VendorTableConfig,
  VenueTableConfig,
} from "./table-config";

export const HOME_PAGE_ROUTE = "/";
export const VENUE_ROUTE = "/venues";
export const VENDORS_ROUTE = "/vendors";
export const RUNNERS_ROUTE = "/runners";

export type Resource = {
  key: string;
  name: string;
  path: string;

  getQuery: DocumentNode;
  getQueryName: string;

  getAllQuery: DocumentNode;
  getAllQueryName: string;

  createMutation?: DocumentNode;
  updateMutation: DocumentNode;
  deleteMutation: DocumentNode;
  isDuplicable?: boolean;
  showLoginLink?: boolean;

  tableConfig?: TableConfig;
  duplicateResource?: DocumentNode;
  isEditable?: boolean;
  showRefresh?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
};
export interface IResourceObject {
  [key: string]: Resource;
}

export const Resources: IResourceObject = {
  venue: {
    name: "Venue",
    path: "/venues",
    getQuery: GET_VENUE,
    getQueryName: "getVenue",
    getAllQuery: GET_VENUES,
    getAllQueryName: "getVenues",
    createMutation: CREATE_VENUE,
    updateMutation: UPDATE_VENUE,
    deleteMutation: DELETE_VENUE,
    key: "venue",
    form: VenueForm,
    tableConfig: VenueTableConfig,
  },

  vendor: {
    name: "Vendor",
    path: "/vendors",
    getQuery: GET_VENDOR,
    getQueryName: "getVendor",
    getAllQuery: GET_VENDORS,
    getAllQueryName: "getVendors",
    createMutation: CREATE_VENDOR,
    updateMutation: UPDATE_VENDOR,
    deleteMutation: DELETE_VENDOR,
    form: VendorForm,
    key: "vendor",
    isDuplicable: true,
    showLoginLink: true,
    tableConfig: VendorTableConfig,
    duplicateResource: DUPLICATE_VENDOR,
  },

  runner: {
    name: "Runner",
    path: "/runners",
    getQuery: GET_RUNNER,
    getQueryName: "getRunner",
    getAllQuery: GET_RUNNERS,
    getAllQueryName: "getRunners",
    createMutation: CREATE_RUNNER,
    updateMutation: UPDATE_RUNNER,
    deleteMutation: DELETE_RUNNER,
    form: RunnerForm,
    key: "runner",
    tableConfig: RunnerTableConfig,
    showLoginLink: true,
  },
};

export const SuperAdminResource: Resource = {
  name: "Admin",
  path: "/admins",
  getQuery: GET_ADMIN,
  getQueryName: "getAdmin",
  getAllQuery: GET_ADMINS,
  getAllQueryName: "getAdmins",
  createMutation: CREATE_ADMIN,
  updateMutation: UPDATE_ADMIN,
  deleteMutation: DELETE_ADMIN,
  form: AdminForm,
  key: "admin",
  tableConfig: AdminTableConfig,
};

export const RefundOrderResource: Resource = {
  name: "Refund Request",
  path: "/refund",
  getQuery: GET_REFUND_BY_ID,
  getQueryName: "getRefund",
  getAllQuery: GET_REFUND_ORDERS, //
  getAllQueryName: "getAllRefunds",
  tableConfig: OrderRefundTableConfig,
  updateMutation: UPDATE_REFUND,
  deleteMutation: DELETE_REFUND,
  isDuplicable: false,
  showLoginLink: false,
  form: null,
  key: "refund",
  isEditable: false,
  showRefresh: true,
};

import { TableConfig } from '../components/table'
import { Admin, Runner } from '../types'
import { Vendor } from '../types'
import { Venue, VenueSection } from '../types'

export const VenueTableConfig: any = {
  
  name: {
    headerText: 'Name',
    process: (venue: Venue) => venue.name,
  },
  section: {
    headerText: 'Sections',
    process: (venue: Venue) => {
      const filteredSections = venue.sections?.filter(
        (section) => !section.isDeleted
      )
      const sectionLabels =
        filteredSections &&
        filteredSections.map((section: VenueSection) => section.label).join(',')
      return sectionLabels
    },
  },
  venueType:{
    headerText: 'Venue Type',
    process: (venue: Venue) => venue.venueType,
  },
  disabled: {
    headerText: 'Status',
    process: (venue: Venue) => (venue.isDisabled
      ? 'Disabled' : 'Active')
  },
}

export const AdminTableConfig: any = {
  name: {
    headerText: 'Name',
    process: (admin: Admin) => admin.name,
  },
  email: {
    headerText: 'Email',
    process: (admin: Admin) => admin.email,
  },
}

export const RunnerTableConfig: TableConfig = {
  name: {
    headerText: 'Name',
    process: (runner: Runner) => runner.name,
  },
  section: {
    headerText: 'Contact',
    process: (runner: Runner) => runner.phoneNumber,
  },
  disabled: {
    headerText: 'Status',
    process: (runner: Runner) => (runner.isDisabled ? 'Disabled' : 'Active'),
  },
}

export const VendorTableConfig: TableConfig = {
  name: {
    headerText: 'Trading Name',
    process: (vendor: Vendor) => vendor.tradingName,
  },
  section: {
    headerText: 'Business Name',
    process: (vendor: Vendor) => vendor.businessName,
  },
  disabled: {
    headerText: 'Status',
    process: (vendor: Vendor) => (vendor.isDisabled ? 'Disabled' : 'Active'),
  },
}

export const OrderRefundTableConfig: TableConfig = {
  orderNumber: {
    headerText: 'Order Number',
    process: (refund: any) => refund.orderNumber
  },
  patronName: {
    headerText: 'Patron ',
    process: (refund: any) => refund.patron.name,
  },
  /*
  patronEmail: {
    headerText: "Patron Email",
    process: (refund: any) => refund.patron.email,
  }, */
  requestedRefundAmount: {
    headerText: 'Amount',
    //TODO: Get currency from order
    process: (refund: any) => refund.amountRequested,
  },
  openDate: {
    headerText: 'Date',
    process: (refund: any) => new Date(refund.openDate).toDateString(),
  },
  refundStatus: {
    headerText: 'Status',
    process: (refund: any) => refund.refundStatus,
  },
  refundAmount: {
    headerText: 'Refunded Amount',
    process: (refund: any) =>
      refund.amountRefunded ? 'AUD ' + refund.amountRefunded : '-',
  },
  closeDate: {
    headerText: 'Processed Date',
    process: (refund: any) =>
      refund.closeDate ? new Date(refund.closeDate).toDateString() : '-',
  },
  /* totalRefundedItems: {
    headerText: 'Final Refunded Items Count',
    process: (refund: any) => refund.finalRefundItems?.length || '-',
  },
  */
}

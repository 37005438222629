import React from "react";
import { createContext, ReactElement } from "react";
import { IContextValue, useIContext } from "./common";

type AppContextType = {
  adminID?: IContextValue<string>;
};

export const AppContext = createContext<AppContextType>({});

interface Props {
  children: ReactElement;
}

function AppContextProvider({ children }: Props) {
  const value: AppContextType = {
    adminID: useIContext<string>(""),
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppContextProvider;

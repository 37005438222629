import { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  HOME_PAGE_ROUTE,
  RefundOrderResource,
  Resources,
  SuperAdminResource,
} from "./resource/resource-config";
import Dashboard from "./pages/dashboard";
import { ResourceCreate } from "./pages/resource-create";
import { ResourceUpdate } from "./pages/resource-update";
import { ResourceList } from "./pages/resource-list";
import { ChargesManagementDashboard } from "./pages/charges-management";
import "@aws-amplify/ui-react/styles.css";
import { Loader } from "./elements/loader";
import { GET_USER_INFO } from "./graphql/query";
import OrdersManagementDashboard from "./pages/orders-management";
import { fetchToken } from "./firebase/firebase";
import { UserType } from "./types";
import { browserName } from "react-device-detect";
import { getOperatingSystem } from "./utils/get-os";
import { ADD_DEVICE } from "./graphql/mutation";
import { AppContext } from "./context/app-context";
import { NotificationContext } from "./context/notification-context";
import { ReconciliationPage } from "./pages/reconciliation-report-";
import { UnAuthorized } from "./components/un-authorized";
import { useQueryHook } from "./hooks/use-query.hook";
import { useMutationHook } from "./hooks/use-mutation.hook";
import RefundsManagementDashboard from "./pages/refunds-management";

export const Wizard = () => {
  const location = useLocation();
  const { adminID } = useContext(AppContext);

  const { data, loading } = useQueryHook({
    query: GET_USER_INFO,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
  const notificationContext = useContext(NotificationContext);

  const userInfo = data?.getUserInfo;
  const group = userInfo?.groups || [];
  const navigate = useNavigate();

  const isAdmin = group.includes("admin");
  const isSuperAdmin = group.includes("super_admin");

  const dashboardResources = Resources;
  if (isSuperAdmin) {
    dashboardResources["admin"] = SuperAdminResource;
  }

  const admin = data?.getUserInfo?.admin;

  const { mutate: updateResource } = useMutationHook({ mutation: ADD_DEVICE });

  const fetchDeviceDetails = async () => {
    await fetchToken().then(
      (data) => {
        updateResource({
          variables: {
            input: {
              id: admin?._id,
              deviceDetails: {
                browser: browserName,
                os: getOperatingSystem(window),
                token: data,
              },
              userType: UserType.admin,
            },
          },
        });
      },
      (error) => {
        console.log("Error fetching token");
        console.log(error);
      }
    );
  };

  const init = async () => {
    if (userInfo) {
      if (location.pathname === "/" && admin) {
        // setDeviceDetailsLoading(true);
        await fetchDeviceDetails();
        //setDeviceDetailsLoading(false);
        navigate("/venues/list");
      }
      adminID?.setValue(admin?._id || "");
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      window.location.reload();
    }, 25 * 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    notificationContext.clear();
  }, [location]);

  useEffect(() => {
    if (userInfo != null) {
      init();
    }
  }, [data]);

  if (loading) {
    return <Loader visible={true} />;
  }

  if (!isAdmin && !isSuperAdmin) {
    return <UnAuthorized />;
  }

  return (
    <Routes>
      <Route path={HOME_PAGE_ROUTE} element={<Dashboard />}>
        {Object.keys(dashboardResources).map((key) => {
          const resource = dashboardResources[key];
          return (
            <Route key={key}>
              <Route
                path={resource.path + "/create"}
                element={<ResourceCreate resource={resource} />}
              />
              <Route
                path={resource.path + "/update/:id"}
                element={<ResourceUpdate resource={resource} />}
              />
              <Route
                path={resource.path + "/list"}
                element={<ResourceList resource={resource} />}
              />
            </Route>
          );
        })}
        <Route path={"/charges"} element={<ChargesManagementDashboard />} />
        <Route path={"/report"} element={<ReconciliationPage />} />
        <Route path={`${RefundOrderResource.path}/update/:id`} element={<ResourceUpdate resource={RefundOrderResource} />} />
        <Route path={`${RefundOrderResource.path}/list`} element={<RefundsManagementDashboard resource={RefundOrderResource} />} />
        <Route path={"/orders"} element={<OrdersManagementDashboard />} />
      </Route>
    </Routes>
  );
};

import Joi from "joi";
import { TerminatingRefundStatus } from "../../../types";

export const schema = Joi.object().keys({
  refundStatus: Joi.string()
    .trim()
    .required()
    .label("Refund Status")
    .valid(
      TerminatingRefundStatus.DENIED,
      TerminatingRefundStatus.PARTIALLY_REFUNDED,
      TerminatingRefundStatus.REFUNDED
    ),
  amountRefunded: Joi.number().allow(null).label("amountRefunded"),
  closeDate: Joi.date().allow(null).label("Close Date"),
  transactionId: Joi.string()
    .allow(null)
    .allow("")
    .trim()
    .label("Transaction ID"),
  reviewerComments: Joi.array().label("reviewerComments"),
});

import React, { ReactElement } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";
import { Brightness1, ContentCopy } from "@mui/icons-material";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";

export type TableConfig = {
  [key: string]: {
    headerText: string;
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    process: (item: any) => string | ReactElement | undefined;
  };
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Array<any>;
  config?: TableConfig;
  isDuplicable?: boolean;
  showLoginLink?: boolean;
  isEditable?: boolean;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onDeleteClick?: (item: any) => void;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onEditClick?: (item: any) => void;
  onEventEmit?: (item: any) => void;
  onRowClick?: (item: any) => void;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: "white",
    height: "50px",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    height: "50px",
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)((props) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#e0e0e0",
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function DenseTable({
  data = [],
  config = {},
  isDuplicable = false,
  showLoginLink = false,
  isEditable = true,
  onDeleteClick = () => { },
  onEditClick = () => { },
  onEventEmit = () => { },
  onRowClick = () => { }
}: Props) {
  const navigate = useNavigate();
  const onResourceDuplicate = (item: any) => {
    // odd strategy, fix later
    if ("tradingName" in item) {
      const duplicateData = {
        resource: "vendor",
        data: item,
      };
      localStorage.setItem("duplicateData", JSON.stringify(duplicateData));
      navigate("/vendors/create");
    }
  };
  localStorage.getItem("duplicateData") &&
    localStorage.removeItem("duplicateData");

  const hasActions = isEditable || isDuplicable || showLoginLink

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {Object.keys(config).map((key: string, index) => {
              return (
                <StyledTableCell key={`${key}-${index}`}>
                  {config[key].headerText}
                </StyledTableCell>
              );
            })}
            {hasActions && <StyledTableCell>Actions</StyledTableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item, index) => (
            <StyledTableRow key={`${item.id}-${index}}`} onClick={() => onRowClick({ item })}
              hover={!isEditable}
            >
              {Object.keys(config).map((key: string, keyIndex) => {
                return (
                  <StyledTableCell
                    key={`${key}-${keyIndex}`}
                    onClick={(event) => onEventEmit({ event, item, key, scope: "cell" })}
                  >
                    {config[key].process(item)}
                  </StyledTableCell>
                );
              })}
              {
                hasActions &&
                <StyledTableCell>
                  <Box width={100} display="flex">
                    {isEditable &&
                      <Box width={40}>
                        <EditIcon
                          onClick={() => onEditClick(item)}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Box>}
                    {isDuplicable ? (
                      <Box width={40}>
                        <ContentCopy
                          onClick={() => onResourceDuplicate(item)}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    ) : (
                      <></>
                    )}

                    {showLoginLink ? (
                      <Box width={40}>
                        <Link href={item.signInLink} target="_blank">
                          <LoginIcon style={{ color: "rgba(0, 0, 0, 0.87)" }} />
                        </Link>
                      </Box>
                    ) : null}

                    <Box width={40} display="none">
                      <DeleteIcon
                        onClick={() => onDeleteClick(item)}
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        width={50}
                      />
                    </Box>
                  </Box>
                </StyledTableCell>
              }
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

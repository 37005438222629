import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { vendorOrderStatusConfig } from "../constants/constants";
import { OptionWithType } from "../types";
import { Text } from "../elements/text";
import { AddSharp, ChevronRight, ExpandLess } from "@mui/icons-material";
import { customTheme } from "../theme";
import { OrderedItem, OrderStatusHistory, VendorOrder } from "../types/order";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

type VendorOrderDetailCardProps = {
  order: VendorOrder;
};

const VendorOrderDetailCard: React.FC<VendorOrderDetailCardProps> = ({
  order,
}) => {
  const [showOrderItems, setShowOrderItems] = useState<boolean>(false);
  const [orderStatusHistory, setOrderStatusHistory] = useState<boolean>(false);

  const handleCollapseItems = () => {
    setShowOrderItems(!showOrderItems);
  };

  const handleOrderStatusClick = () => {
    setOrderStatusHistory(!orderStatusHistory);
  };

  const formatDateTime = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour12: true,
    });
  };

  return (
    <>
      <Box
        sx={{
          my: 1,
          p: 1,
          borderRadius: 2,
          border: "3px solid #D1C7C7",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            borderRadius: 2,
          }}
        >
          <Box>
            <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
              <Text
                text={`Order #${order.orderNumber || ""}`}
                fontSize={14}
                fontWeight={600}
                color={"#300304"}
              />
              <Box
                sx={{
                  p: 0.5,
                  m: 0.5,
                  mr: 2,
                  borderRadius: 1,
                }}
              >
                {order?.isOrderUpdated && (
                  <Text fontWeight={700} text="(E)" color={"#950305"} />
                )}
              </Box>
            </Box>
            <Typography fontSize={14} fontWeight={500} color={"#300304"}>
              {order?.vendor?.tradingName}
            </Typography>
          </Box>
          <Box>
            <Typography
              color={vendorOrderStatusConfig[order.orderStatus].color}
              fontSize={12}
              fontWeight={500}
            >
              {vendorOrderStatusConfig[order.orderStatus].text}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#D0D5DD" }} />
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Text text={"Order Details"} fontSize={14} fontWeight={200} />
          <Box display="flex" alignItems={"center"}>
            <Text
              text={`x${order.items?.length.toString()}`}
              fontSize={16}
              fontWeight={500}
            />
            {showOrderItems ? (
              <ExpandLess onClick={handleCollapseItems} />
            ) : (
              <ChevronRight onClick={handleCollapseItems} />
            )}
          </Box>
        </Box>

        <Divider sx={{ borderColor: "#D0D5DD" }} />
        {showOrderItems && (
          <Grid item xs={12}>
            {order?.items?.map((item: OrderedItem, index: number) => {
              return (
                <Box key={`${item._id}-${index}`}>
                  <Box
                    px={2}
                    display={"flex"}
                    style={{
                      textDecoration: item.isRejected ? "line-through" : "none",
                    }}
                  >
                    <Box mr={2} display={"flex"}>
                      <Typography
                        fontFamily={customTheme.fontFamily}
                        fontSize={16}
                        fontWeight={600}
                        color={"#2A2A2A"}
                      >
                        {item?.count}
                      </Typography>
                      {item?.isUpdated && (
                        <Box ml={"4px"}>
                          <Typography
                            fontFamily={customTheme.fontFamily}
                            fontSize={16}
                            fontWeight={600}
                            color={"#F84244"}
                          >
                            ({item?.updatedQuantity})
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Typography
                      fontFamily={customTheme.fontFamily}
                      fontSize={16}
                      fontWeight={700}
                      color={"#2A2A2A"}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  {item.customisations && item.customisations.length > 0 && (
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      px={2}
                      style={{
                        textDecoration: item.isRejected
                          ? "line-through"
                          : "none",
                      }}
                    >
                      <AddSharp sx={{ marginRight: 1, fontSize: 16 }} />
                      {item.customisations?.map(
                        (
                          customisationOption: OptionWithType,
                          index: number
                        ) => {
                          return (
                            <Typography
                              key={`${customisationOption._id}-${index}`}
                              fontSize={14}
                            >
                              {`${index == 0 ? "" : ", "}${
                                customisationOption.name
                              }`}
                            </Typography>
                          );
                        }
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Grid>
        )}

        <Divider sx={{ borderColor: "#D0D5DD" }} />

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Text text={"Order Status History"} fontSize={14} fontWeight={200} />
          <Box display="flex" alignItems={"center"}>
            <Text fontSize={16} fontWeight={500} />
            {orderStatusHistory ? (
              <ExpandLess onClick={handleOrderStatusClick} />
            ) : (
              <ChevronRight onClick={handleOrderStatusClick} />
            )}
          </Box>
        </Box>

        <Divider sx={{ borderColor: "#D0D5DD" }} />

        {orderStatusHistory && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
                ml: 2
              },
            }}
          >
                {order?.history?.map(
                  (order: any, index: number, array: OrderStatusHistory[]) => (
                    <TimelineItem key={`${index}`}>
                      <TimelineSeparator>
                        <TimelineDot color="success" />
                        {index !== array.length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            mr={18}
                          >
                            <Typography fontSize={14}>
                              {order.status}
                            </Typography>
                          </Box>
                          {order.userType ? (
                            <Typography
                              fontSize={12}
                              color="#6B6B6B"
                            >{`${order.userType} | ${order.message}`}</Typography>
                          ) : (
                            <Typography fontSize={12} color="#6B6B6B">
                              {order.message}
                            </Typography>
                          )}
                          <Box>
                            <Typography fontSize={12} color="#6B6B6B">
                              {formatDateTime(order.createdAt)}
                            </Typography>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  )
                )}
              </Timeline>
            )}
      </Box>

      {order?.isOrderUpdated && (
        <Box ml={2}>
          <Text
            text="(*) Item updated quantity"
            color="#EC8B8C"
            fontSize={14}
          />
        </Box>
      )}
    </>
  );
};

export default VendorOrderDetailCard;

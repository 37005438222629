import React, { useState } from "react";
import { Autocomplete, Box, Collapse, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {  DeliveryArea, VenueSection } from "../../types";
import { CustomInput } from "../../elements/input";
import { ConfirmationDailog } from "../../elements/confirmation-dialog";
import { Text } from "../../elements/text";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { VenueImageCard } from "./image-card";

interface DeliveryAreaProps {
  deliveryArea: DeliveryArea;
  index: number;
  deleteDeliveryArea: (_deliveryAreaId: string) => void;
  onChangeDeliveryArea: (
    _index: number,
    _updatedDeliveryArea: DeliveryArea,
  ) => void;
  venueSections: VenueSection[];
}

const DeliveryAreaInput = ({
  deliveryArea,
  index,
  deleteDeliveryArea,
  onChangeDeliveryArea,
  venueSections,
}: DeliveryAreaProps) => {
  const getSelectedVenueSections = (): VenueSection[] => {
    const sections = venueSections;
    return sections.filter((section) => {
      if (deliveryArea.serviceableSectionsIds?.includes(section.id))
        return section;
    });
  };

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [expandImageControls, setExpandImageControls] = useState(false);

  return (
    <Box
      display={"flex"}
      mb={2}
      flexDirection={"column"}
      boxShadow={"0px 0px 4px #999"}
      px={4}
      py={2}
    >
      <ConfirmationDailog
        open={showConfirmationDialog}
        title={`Delete delivery-area association?`}
        text={
          "This delivery area is associated with one or more sections, are you sure you want to delete it?"
        }
        handleCancel={() => setShowConfirmationDialog(false)}
        handleContinue={() => {
          deleteDeliveryArea(deliveryArea.id);
          setShowConfirmationDialog(false);
        }}
      />
      <Box>
        <CustomInput
          autoFocus={true}
          value={deliveryArea.label}
          onChange={(value: string) => {
            deliveryArea.label = value;
            onChangeDeliveryArea(index, deliveryArea);
          }}
        />
      </Box>

      <Box my={2}>
        <Autocomplete
          multiple={true}
          id="tags-standard"
          options={venueSections}
          getOptionLabel={(section: VenueSection) => section.label}
          value={getSelectedVenueSections()}
          onChange={(e, values) => {
            const updatedServiceableSectionsIds = values.map(
              (value) => value.id
            );
            deliveryArea.serviceableSectionsIds =
              updatedServiceableSectionsIds;
            onChangeDeliveryArea(index, deliveryArea);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={"Serviceable Sections"}
            />
          )}
        />
      </Box>

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        my={2}
      >
        <DeleteIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (
              deliveryArea.serviceableSectionsIds &&
              deliveryArea.serviceableSectionsIds?.length > 0
            ) {
              setShowConfirmationDialog(true);
            } else {
              deleteDeliveryArea(deliveryArea.id);
            }
          }}
        />
      </Box>
      <Box p={2} sx={{ backgroundColor: "#e8e8e8bb" }} borderRadius={"8px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{ cursor: "pointer" }}
          onClick={() => setExpandImageControls(!expandImageControls)}
        >
          <Text text="Image (Optional)" fontSize={18} />
          {expandImageControls ? <ExpandLessRounded /> : <ExpandMoreRounded />}
        </Box> 

         <Collapse in={expandImageControls} timeout={"auto"} unmountOnExit>
          <VenueImageCard
            previewImageSrc={deliveryArea.daNavigationImageUrl}
            label={"VD Navigation"}
            toolTipLabel={
              "This is used for helping patron navigate to collection-point (CP Orders)"
            }
            resourceImageSrc={deliveryArea.daNavigationImageUrl}
            onUploadImage={(url: string) => {
              const updatedDeliveryArea: DeliveryArea = {
                ...deliveryArea,
                daNavigationImageUrl: url,
              };
              onChangeDeliveryArea(index, updatedDeliveryArea);
            }}
            resourceImageNote={
              "This is used for helping patron"
            }
          />
        </Collapse> 
       </Box>
    </Box>
  );
};

export default DeliveryAreaInput;

import Joi from "joi";
import { CollectionPoint, VenueSection } from "../../types";
import { ServiceMethod } from "../../types/order";

export const venueDetailsSchema = Joi.object().keys({
  name: Joi.string()
    .trim()
    .required()
    .messages({
      "string.pattern.base": `"Name" - No special characters allowed`,
    })
    .label("Name"),
  address: Joi.string().trim().required().label("Address"),
  accountID: Joi.string().required().label("Account ID"),
  venueType: Joi.string().required().label("Venue Type"),
  stripePaymentCapture: Joi.string().required().label("Stripe payment capture"),
  venueCurrency: Joi.required().label("Venue Currency"),
  minimumOrderPrice: Joi.number()
    .min(0)
    .message("Minimum order price can't be less than 0")
    .label("Minimum Order Value"),
  fixedServiceCharge: Joi.number()
    .min(0)
    .message("Fixed service charge can't be less than 0")
    .label("Fixed Service Charge"),
  patronNavigationImageUrl: Joi.string().optional().allow(null).allow(""),
  sections: Joi.array()
    .items({
      label: Joi.string()
        .allow("")
        .trim()
        .regex(/^[A-Za-z0-9- ]+$/)
        .required()
        .messages({
          "string.pattern.base": `"Sections" - No special characters allowed`,
        })
        .label("Section Name"),
      id: Joi.string(),
      isClickAndCollect: [Joi.bool().optional(), Joi.allow(null)],
      serviceMethods: Joi.array().items(Joi.string()).required(),
      cncNavigationImageUrl: Joi.string().optional().allow(null).allow(""),
      isDeleted: [Joi.bool().optional(), Joi.allow(null)],
    })
    .min(1)
    .messages({
      "sections.isEmpty": "Venue must contain at least one section.",
      "sections.isLabelEmpty": "Section name cannot be empty",
    })
    .custom((sections, helpers) => {
      if (!sections.some((section: VenueSection) => !section.isDeleted)) {
        return helpers.error("sections.isEmpty");
      }
      const containsEmptySectionLabel = sections.some(
        (section: VenueSection) => {
          if (!section.label && !section.isDeleted) {
            return true;
          }
          return false;
        }
      );
      if (containsEmptySectionLabel) {
        return helpers.error("sections.isLabelEmpty");
      }
      return sections;
    })
    .label("Sections"),
  collectionPoints: Joi.array()
    .items({
      label: Joi.string()
        .allow("")
        .trim()
        .regex(/^[A-Za-z0-9- ]+$/)
        .required()
        .messages({
          "string.pattern.base": `"Collection Points" - No special characters allowed`,
        })
        .label("Collection Point Name"),
      id: Joi.string().optional(),
      serviceableSectionsIds: Joi.array().items(Joi.string()).allow(null),
      cpNavigationImageUrl: Joi.string().optional().allow(null).allow(""),
      isDeleted: [Joi.bool().optional(), Joi.allow(null)],
    })
    .min(0)
    .messages({
      "collectionPoints.isLabelEmpty": "Collection Point name cannot be empty",
    })
    .custom((collectionPoints, helpers) => {
      const containsEmptySectionLabel = collectionPoints.some(
        (collectionPoint: CollectionPoint) => {
          if (!collectionPoint.label && !collectionPoint.isDeleted) {
            return true;
          }
          return false;
        }
      );
      if (containsEmptySectionLabel) {
        return helpers.error("collectionPoints.isLabelEmpty");
      }
      return collectionPoints;
    })
    .label("Collection Points"),
});

export const venueImageUrlSchema = Joi.object().keys({
  ttsBrandingImageUrl: Joi.string()
    .optional()
    .allow("")
    .uri()
    .label("TTS Branding"),
  venueMapImageUrl: Joi.string().uri().required().label("Venue Map Image"),
  cncBrandingImageUrl: Joi.string()
    .optional()
    .allow("")
    .uri()
    .label("CNC Branding"),
  vendorListImageUrl: Joi.string()
    .optional()
    .allow("")
    .uri()
    .label("Vendor List Image"),
    printReceiptImageUrl: Joi.string()
    .optional()
    .allow("")
    .uri()
    .label("print Receipt Image"),
});

import React from "react";
import { Box, FormControl, TextField } from "@mui/material";
import { Text } from "./text";

interface Props {
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void;
  value?: string | number | Date;
  title?: string;
  asterisk?:string;
  required?: boolean;
  autoFocus?: boolean;
  type?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  placeHolder?: string;
  min?: number;
  max?: number;
  isMandatory?: boolean;
  label?:string
}

export const CustomInput = ({
  onChange = () => {},
  value = "",
  title,
  type,
  isMandatory = false,
  error,
  helperText,
  placeHolder,
  required = false,
  autoFocus = false,
  disabled = false,
  min,
  max,
  label,
}: Props) => {
  const onValueChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (type === "number") {
      const parsedValue = value ? parseFloat(value) : undefined;
      onChange(parsedValue);
      return;
    }
    onChange(value);
  };
  return (
    <FormControl fullWidth>
      {title && (
        <Box mb={1} sx={{ display: "flex", flexDirection: "row" }}>
          {isMandatory ? (
            <>
              <Text text={title} />
              <Text fontSize={17} color="red" text="*" />
            </>
          ) : (
            <Text text={title} />
          )}
        </Box>
      )}

      <TextField
        disabled={disabled}
        type={type}
        required={required}
        autoFocus={autoFocus}
        value={value}
        error={error}
        label={label}
        helperText={helperText}
        sx={{
          "& .MuiInputLabel-outlined": {
            marginTop:'-10px',
            paddingBottom:'10px',
            paddingTop:'0px'
          },
          "& .MuiOutlinedInput-notchedOutline": {
           marginTop:'-4px',
           
          },
        }}
        inputProps={{
          style: {
            height: "35px",
            padding:"0px",
            paddingLeft: "15px",

          
          },
          step: "0.01",
          min: min,
          max: max,
        }}
        onChange={onValueChange}
        placeholder={placeHolder}
      />
    </FormControl>
  );
};

import { v4 } from "uuid";

export const getFileExtension = (fileName: string): string => {
  const fileExtension = fileName.slice(
    ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
  );
  return fileExtension;
};

export const toFile = async (
  url: string,
  fileExtension: string
): Promise<File> => {
  const res = await fetch(url);
  const blob = await res.blob();
  const file = new File([blob], v4() + `.${fileExtension}`, {
    type: "image/png",
  });
  return file;
};

export const humanFileSize = (bytes: number, dp = 1): string => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

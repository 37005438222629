import { Autocomplete, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../context/order-context";
import { CustomInput } from "../elements/input";
import { CustomSelect, MenuItemType } from "../elements/select";
import { Text } from "../elements/text";
import { GET_VENDORS } from "../graphql/query";
import { useQueryHook } from "../hooks/use-query.hook";

const timeDimensions = [
  { id: "minutes", label: "Minutes" },
  { id: "hours", label: "Hours" },
  { id: "days", label: "Days" },
];

export interface IDateFilter {
  end: string;
  start: string;
}
interface Props {
  onFilter?: () => void;
}

export const OrderFilter = ({ onFilter = () => {} }: Props) => {
  const orderContext = useContext(OrderContext);
  const [vendorFilterValuesIDs, setVendorFilterValuesIDs] = useState<string[]>(
    []
  );
  const isLessThanFiveDays = () => {
    if (orderContext.startTimeUnit?.value === "days") {
      return parseFloat(orderContext?.startTime?.value || "0") <= 5;
    }
  };
  const isLessThan24Hours = () => {
    if (orderContext.startTimeUnit?.value === "hours") {
      return parseFloat(orderContext?.startTime?.value || "0") <= 24;
    }
    if (orderContext.startTimeUnit?.value === "minutes") {
      return parseFloat(orderContext?.startTime?.value || "0") <= 24 * 60;
    }
  };

  const isTimeLessThan24Hours = isLessThan24Hours();
  const isDaysLessThanFive = isLessThanFiveDays();

  const onFilterClickHandler = () => {
    if (isTimeLessThan24Hours || isDaysLessThanFive) {
      onFilter();
    }
  };

  const getVendorFilterValues = (): any[] => {
    const { data } = useQueryHook({ query: GET_VENDORS });
    return data?.getVendors || [];
  };

  const getSelectedFilterValues = (): any[] => {
    const vendorFilterValues = getVendorFilterValues();
    return vendorFilterValues.filter((vendorFilterValue) =>
      (orderContext.vendorIdFilter?.value || vendorFilterValuesIDs).includes(
        vendorFilterValue._id
      )
    );
  };

  const timeDimensionsData = timeDimensions.map((timeDimension: any) => {
    const menuItem: MenuItemType = {
      value: timeDimension.id as string,
      label: timeDimension.label,
    };
    return menuItem;
  });
  return (
    <Box flex={1} alignItems={"center"}>
      <Box display="flex" m={0} alignItems={"center"} >
        <Box mr={2} sx={{ height: "40px", width: "100px",  justifyContent:"center", marginTop:1, marginLeft:1}}>
          <CustomInput
            value={orderContext.startTime?.value}
            onChange={orderContext.startTime?.setValue}
          />
        </Box>
        <Box mr={2} sx={{ height: "40px", width: "120px" }}>
          <CustomSelect
            value={orderContext.startTimeUnit?.value}
            onChange={(value: string) => {
              orderContext.startTimeUnit?.setValue(value);
            }}
            data={timeDimensionsData}
          />
        </Box>
        <Box mr={2} sx={{ minWidth: "20%", maxWidth: "30%" }}>
          <Autocomplete
            multiple={true}
            id="vendor-filter-standard"
            options={getVendorFilterValues()}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            value={getSelectedFilterValues()}
            getOptionLabel={(option) => option.tradingName || ""}
            onChange={(e, values) => {
              const updatedVendorIds = values.map((value) => value._id);
              orderContext.vendorIdFilter?.setValue(updatedVendorIds);
              setVendorFilterValuesIDs(updatedVendorIds);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label="Select Vendors"
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option._id}>
                  {option.tradingName}
                </li>
              );
            }}
          />
        </Box>
        <Box sx={{ height: "40px" }}>
          <Button
            onClick={onFilterClickHandler}
            sx={{ width: "100px", height: "40px" }}
            variant="contained"
            disabled={
              isTimeLessThan24Hours || isDaysLessThanFive ? false : true
            }
          >
            Filter
          </Button>
        </Box>
      </Box>
      <Box mt={1} mb={1}>
        
        {!isTimeLessThan24Hours &&
        orderContext.startTimeUnit?.value === "hours" ? (
          <Text color="red" text="You can only filter past 24 hours orders" />
        ) : (
          ""
        )}
        {!isDaysLessThanFive &&
        orderContext.startTimeUnit?.value === "days"? (
          <Text color="red" text="You can only filter past 5 days orders" />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import { Text } from "./text";
import { customTheme } from "../theme";

export type MenuItemType = {
  value: string;
  label: string;
};

interface Props {
  data: Array<MenuItemType>;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  value?: string;
  title?: string;
  asterisk?:string;
  required?: boolean;
  disabled?: boolean;
  floatingLabel?: boolean;
  isMandatory?: boolean;
}

export const CustomSelect = ({
  data,
  required = false,
  onChange,
  value = "",
  title,
  disabled = false,
  floatingLabel = false,
  isMandatory = false,
}: Props) => {
  return (
    <FormControl fullWidth>
      {title &&
        (floatingLabel ? (
          <InputLabel size="small" id="select-label" sx={{ fontSize: 14 }}>
            {title}
          </InputLabel>
        ) : (
          <Box mb={1} sx={{ display: 'flex', flexDirection:"row" }}>
            {isMandatory ? (
            <>
              <Text text={title} />
              <Text fontSize={17} color="red" text="*" />
            </>
          ) : (
            <Text text={title} />
          )}
          </Box>
        ))}
      <Select
        labelId="select-label"
        required={required}
        disabled={disabled}
        label={title}
        sx={{
          height: "40px",
          color: "#2A2A2A",
          fontFamily: customTheme.fontFamily
        }}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {data.map((item: MenuItemType) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>

    </FormControl>
  );
};

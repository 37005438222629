import { Box } from "@mui/material";
import React from "react";
import { PatronOrder } from "../types/order";
import OrderCard from "./order-card";

type orderListProps = {
  orders: PatronOrder[];
  selectedOrder?: string;
  // eslint-disable-next-line no-unused-vars
  onSelectOrder: (order: PatronOrder) => void;
};

const OrderList: React.FC<orderListProps> = ({
  orders,
  onSelectOrder,
  selectedOrder,
}) => {
  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        {orders?.map((order: PatronOrder, index: number) => {
          const isSelected = selectedOrder === order._id;
          return (
            <Box
              key={`${order._id}_${index}`}
              onClick={() => {
                onSelectOrder(order);
              }}
            >
              <OrderCard order={order} isSelected={isSelected} />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default OrderList;

import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Modal,
  Typography,
} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import { Loader } from '../../elements/loader'
import { patronOrderStatusConfig } from '../../constants/constants'
import { Text } from '../../elements/text'
import {
  PatronOrder,
  RefundOrder,
  ServiceMethod,
  VendorOrder,
} from '../../types/order'
import { useQueryHook } from '../../hooks/use-query.hook'
import { GET_PATRON_ORDER_BY_ORDER_NO } from '../../graphql/query'
import { extractAllErrorMessages } from '../../utils/graphql'
import { parsePhoneNumber } from 'libphonenumber-js'
import RefundOrderDetailCard from './refund-order-detail-card'
import { ManualRefundModal } from './manual-refund-modal'
import { RefundStatus, RefundStatusForm, TerminatingRefundStatus } from '../../types'
import { DenyRefundModal } from './deny-refund-request-modal'
import { AcceptRefundModal } from './accept-refund-modal'

type RefundOrderDetailModalProps = {
  refundOrder: RefundOrder
  openModal: boolean
  resourcePath: string
  onClose: () => void
}
const RefundOrderDetailModal: React.FC<RefundOrderDetailModalProps> = ({
  refundOrder,
  openModal,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [patronOrder, setPatronOrder] = useState<PatronOrder | null>(null)
  const [serviceMethod, setServiceMethod] = useState(null)
  const [openManualRefundModal, setOpenManualRefundModal] = useState(false)
  const [openDenyRequestModal, setOpenDenyRequestModal] = useState(false)
  const [openAcceptRequestModal, setOpenAcceptRequestModal] = useState(false)
  const patron = patronOrder?.patron

  const location = patronOrder?.location

  const PatronLocation = () => {
    if (!serviceMethod) return <></>
    switch (serviceMethod) {
      case ServiceMethod.DELIVERY:
        return (
          <Text
            text={`Section: ${location?.sectionName}, Row ${location?.row}, Seat ${location?.seat}`}
            fontSize={14}
            fontWeight={600}
          />
        )

      case ServiceMethod.COLLECTION_POINT:
        return (
          <Text
            text={`Section: ${location?.sectionName}, Collection Point: ${location?.collectionPointName}`}
            fontSize={14}
            fontWeight={600}
          />
        )

      default:
        return <></>
    }
  }

  const { fetch: fetchOrder, loading: orderLoading } = useQueryHook({
    query: GET_PATRON_ORDER_BY_ORDER_NO,
    variables: { orderNumber: '123' },
    onCompleted: (data) => {
      setPatronOrder(data.getPatronOrderByOrderNumber)
      setServiceMethod(data.getPatronOrderByOrderNumber.serviceMethod)
    },
    onError: (err) => {
      setPatronOrder(null)
      enqueueSnackbar(extractAllErrorMessages(err), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      onClose()
    },
  })

  useEffect(() => {
    if (openModal) fetchOrder({ orderNumber: refundOrder.orderNumber })
  }, [refundOrder.orderNumber, openModal])

  if (orderLoading) return <Loader visible={orderLoading} />
  const manualRefundFormData: RefundStatusForm = {
    id: refundOrder._id,
    refundStatus: refundOrder.refundStatus,
    amountRefunded: refundOrder.amountRefunded,
    closeDate: refundOrder.closeDate,
    transactionId: refundOrder.transactionId,
    issueDescription: {
      refundReason: refundOrder.issueDescription.refundReason,
      reportorComments: refundOrder.issueDescription.reportorComments || [],
      reviewerComments: refundOrder.issueDescription.reviewerComments || [],
    },
  }

  const isRequestOpen = refundOrder.refundStatus.toString() === RefundStatus.RAISED


  return (
    <>
      {patronOrder && (
        <Modal
          open={openModal}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box>
            <ManualRefundModal
              data={manualRefundFormData}
              openModal={openManualRefundModal}
              onClose={() => {
                setOpenManualRefundModal(false)
              }}
            />
            <DenyRefundModal
              refundOrder={refundOrder}
              openModal={openDenyRequestModal}
              onClose={() => {
                setOpenDenyRequestModal(false)
              }}
            />
            <AcceptRefundModal
              refundOrder={refundOrder}
              openModal={openAcceptRequestModal}
              onClose={() => {
                setOpenAcceptRequestModal(false)
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                maxHeight: '80%',
                overflow: 'auto',
                p: 4,
              }}
              width={'80%'}
            >
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    mb: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Text
                    text={`Order #: ${patronOrder.orderNumber || ''}`}
                    fontSize={16}
                    fontWeight={600}
                  />
                  <Chip
                    label={
                      patronOrderStatusConfig[patronOrder.orderStatus].text
                    }
                    size="small"
                    style={{
                      backgroundColor:
                        patronOrderStatusConfig[patronOrder.orderStatus]
                          .backgroundColor,
                      color:
                        patronOrderStatusConfig[patronOrder.orderStatus]
                          .textColor,
                      paddingLeft: '4px',
                    }}
                  />
                </Box>
                <Divider sx={{ borderColor: '#D0D5DD' }} />
                <Box sx={{ mt: 1 }}>
                  <Typography fontSize={14} fontWeight={500}>
                    {`Patron Details:`}
                  </Typography>
                  <Box
                    sx={{
                      minHeight: 60,
                      backgroundColor: '#F2F4F7',
                      borderRadius: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      paddingLeft: 1,
                    }}
                  >
                    <Box
                      display={'flex'}
                      flexWrap={'wrap'}
                      justifyContent="space-between"
                      alignItems={'center'}
                    >
                      <Text
                        text={patronOrder.patron?.name}
                        fontSize={14}
                        fontWeight={600}
                      />
                      <Box
                        sx={{
                          p: 0.5,
                          m: 0.5,
                          mr: 2,
                          borderRadius: 1,
                          backgroundColor: '#344054',
                        }}
                      >
                        <Text
                          text={parsePhoneNumber(patron?.phoneNumber || '')
                            ?.formatInternational()
                            .toString()}
                          fontSize={10}
                          fontWeight={200}
                          color={'#F2F2F2'}
                        />
                      </Box>
                    </Box>
                    <PatronLocation />
                  </Box>
                </Box>
                <Box>
                  {patronOrder.vendorOrders.map(
                    (vendorOrder: VendorOrder, index: number) => {
                      return (
                        <Box
                          key={`vendor-order-detail-${vendorOrder._id}-${index}`}
                        >
                          <RefundOrderDetailCard
                            order={vendorOrder}
                            refundOrder={refundOrder}
                          />
                        </Box>
                      )
                    }
                  )}
                </Box>
              </Box>
              <Box marginTop={'20px'}>
                <Grid container justifyContent="right">
                  <Button
                    sx={{ ml: '20px' }}
                    variant="text"
                    disabled={!isRequestOpen}
                    onClick={() => {
                      setOpenManualRefundModal(true)
                    }}
                  >
                    Record offline refund details
                  </Button>
                  <Button
                    sx={{ ml: '20px' }}
                    variant="contained"
                    disabled={!isRequestOpen}
                    onClick={() => setOpenAcceptRequestModal(true)}
                  >
                    Approve & Process
                  </Button>
                  <Button
                    sx={{ ml: '20px' }}
                    variant="contained"
                    disabled={!isRequestOpen}
                    onClick={() => setOpenDenyRequestModal(true)}
                  >
                    Reject Request
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  )
}

export default RefundOrderDetailModal

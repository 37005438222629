import React, { useState, useRef, useEffect } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvas-preview";
import { useDebounceEffect } from "./use-debounce-effect";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Box, Grid, Button, IconButton } from "@mui/material";
import { CustomButton } from "../../elements/button";
import config from "../../config";
import {
  getFileExtension,
  humanFileSize,
  toFile,
} from "../../utils/file-utils";
import { useSnackbar } from "notistack";
import { Text } from "../../elements/text";
import { Image } from "../../elements/image";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ViewImageModal } from "./view-image-modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationDailog } from "../../elements/confirmation-dialog";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface ImageUploadProps {
  onUpload?: any;
  src?: string | undefined;
  imageType?: string;
  name?: string | undefined;
  aspectRatio?: number;
  imageNote?: string;
  imageTitle?: string;
}

export default function ImageUpload({
  onUpload = () => {
    return;
  },
  src = "",
  imageType = "",
  name = "",
  aspectRatio = 1 / 1,
  imageNote = "",
  imageTitle = "",
}: ImageUploadProps) {
  const { user } = useAuthenticator();
  const token =
    user.getSignInUserSession()?.getAccessToken()?.getJwtToken() || "";
  const [imgSrc, setImgSrc] = useState(src);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState<PercentCrop>();
  const [cropModel, setCropModelOpen] = useState(false);
  const [completedCrop, setCompletedCrop] = useState<PercentCrop | PixelCrop>();
  const [aspect] = useState(aspectRatio);
  const [uploading, setUploading] = useState(false);
  const [fileExtension, setFileExtension] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [numerator, denominator] = aspectRatio.toString().split("/");

  const hostURL = config.host;

  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const fileExtension = getFileExtension(e.target.files[0]?.name);

      setFileExtension(fileExtension);
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader?.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setCropModelOpen(true);
    }
  }

  async function uploadFile(fileToUpload: File) {
    const formData = new FormData();

    formData.append("type", imageType);
    formData.append("name", name);
    formData.append("file", fileToUpload);

    const response = await fetch(`${hostURL}/file`, {
      method: "POST",
      mode: "cors",
      body: formData,
      headers: {
        authorization: token,
      },
    });
    if (!(response.status == 200 || response.status == 201)) {
      const errorData = await response.json();
      enqueueSnackbar(`Failed to upload image: ${errorData.message}`, {
        variant: "error",
      });
      throw new Error("Failed to upload image");
    }
    const data = await response.json();
    return data?.url;
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const uploadToServer = async () => {
    setUploading(true);
    const imgSrc = document
      .getElementsByTagName("canvas")[0]
      .toDataURL("image/jpeg");

    const file = await toFile(imgSrc, fileExtension);

    if (file.size > 1000000) {
      setUploading(false);
      enqueueSnackbar(
        `File shouldn't exceed 1MB, current file - ${humanFileSize(file.size)}`,
        {
          autoHideDuration: 6000,
          variant: "error",
        }
      );
    } else {
      try {
        const response = await uploadFile(file);
        setUploading(false);
        setCropModelOpen(false);
        onUpload(response);
      } catch (e) {
        setUploading(false);
      }
    }
  };

  function deleteImage() {
    onUpload("");
    setImgSrc("");
    setOpenModal(false);
  }

  return (
    <Grid container>
      <ConfirmationDailog
        open={openModal}
        title={`Delete ${imageTitle} Image?`}
        text={"You can upload another image instead."}
        handleCancel={() => setOpenModal(false)}
        handleContinue={deleteImage}
      />
      <>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Button variant="contained" component="label">
              {imgSrc ? "Change File" : "Upload File"}
              <input
                onClick={(e: any) => {
                  e.target.value = "";
                }}
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                hidden
              />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box width={"fit-content"} ml="auto">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ViewImageModal
                  style={{
                    border: "1px solid rgb(25 118 210 / 50%)",
                    color: "rgb(25, 118, 210)",
                    padding: "6px 12px",
                  }}
                  src={imgSrc}
                />
                <IconButton
                  disabled={!imgSrc}
                  sx={{ color: "#222222" }}
                  onClick={() => setOpenModal(true)}
                >
                  <DeleteIcon sx={{ cursor: "pointer" }} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>

      <Modal
        open={cropModel}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "scroll",
        }}
      >
        <Grid
          sx={{
            padding: "20px",
            background: "white",
          }}
          m="auto"
        >
          <Grid item xs={12}>
            <Box ml="auto" width={100}>
              <Button
                disabled={uploading}
                variant="outlined"
                onClick={() => {
                  if (imgSrc != src) {
                    setImgSrc("");
                  }
                  setCropModelOpen(false);
                }}
                color="secondary"
                sx={{ marginLeft: "auto", width: "100%", mb: 2 }}
              >
                Close
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box m="auto" sx={{ width: "fit-content" }}>
              {Boolean(imgSrc) && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  style={{ width: "100%", maxHeight: "60vh" }}
                >
                  <img
                    style={{ width: "100%", maxHeight: "60vh" }}
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              <div style={{ display: "none" }}>
                {Boolean(completedCrop) && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      width: completedCrop?.width,
                      height: completedCrop?.height,
                    }}
                  />
                )}
              </div>
            </Box>
          </Grid>

          <Box mt={2}>
            <Box p={2}>
              <Text
                text={"Note:"}
                fontSize={16}
                fontWeight={600}
                style={{ textDecoration: "underline" }}
              />
              <Text text={`1. ${imageNote}`} fontWeight={600} />
              <Text
                fontWeight={600}
                fontSize={16}
                text={`2. For effective viewing, the image will be cropped to aspect ratio: ${numerator}:${
                  denominator || 1
                }. Drag the crop tool to select the part of image to be displayed`}
              />
            </Box>
            <CustomButton
              disabled={uploading}
              loading={uploading}
              loadingText={"uploading image, please wait..."}
              onClick={uploadToServer}
              title={"Upload"}
            />
          </Box>
        </Grid>
      </Modal>
    </Grid>
  );
}

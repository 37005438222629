import React, { ReactElement } from "react";
import { Box, Button } from "@mui/material";
import { customTheme } from "../theme";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  title: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  loading?: boolean;
  loadingText?: string | ReactElement;
}

export function CustomButton({
  onClick,
  title,
  disabled = false,
  style = {},
  loading,
  loadingText,
}: Props) {
  return (
    <Box
      sx={{
        fontFamily: customTheme.fontFamily,
        background: disabled ? "grey" : "black",
        width: "100%",
        borderRadius: "30px",
        WebkitBorderRadius: "30px",
        p: "6px",
        "&:hover": {
          background: disabled ? "grey" : "black",
        },
        ...style,
      }}
    >
      <Button
        variant="outlined"
        type="submit"
        sx={{
          fontFamily: customTheme.fontFamily,
          background: disabled ? "grey" : "black",
          width: "100%",
          height: "40px",
          borderRadius: "30px",
          borderColor: "#D2FF28",
          WebkitBorderRadius: "30px",
          color: "#D2FF28",
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "none",
          borderWidth: "2px",
          "&:hover": {
            background: disabled ? "grey" : "black",
            borderColor: "#D2FF28",
          },
        }}
        onClick={() => !disabled && onClick()}
      >
        {loading ? loadingText : title}
      </Button>
    </Box>
  );
}

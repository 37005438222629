import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { patronOrderStatusConfig } from "../constants/constants";
import { Text } from "../elements/text";
import { PatronOrder, VendorOrder } from "../types/order";

type OrderCardProps = {
  order: PatronOrder;
  isSelected: boolean;
};
const OrderCard: React.FC<OrderCardProps> = ({ order, isSelected }) => {
  const createdAtDate = new Date(order.createdAt);
  const date = createdAtDate.toDateString();
  const time = createdAtDate.toLocaleTimeString();

  return (
    <>
      <Box
        sx={{
          ...{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            m: 2,
            p: 2,
          },
          ...(isSelected ? { border: 2, borderColor: "#D2FF28" } : {}),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography fontSize={14} fontWeight={700} color={"#300304"}>
              {`${date}, ${time}`}
            </Typography>

            <Text
              text={`Order #${order.orderNumber || ""}`}
              fontSize={14}
              fontWeight={500}
              color={"#300304"}
            />
          </Box>
          <Box>
            <Chip
              label={patronOrderStatusConfig[order.orderStatus]?.text}
              size="small"
              style={{
                backgroundColor:
                  patronOrderStatusConfig[order.orderStatus]?.backgroundColor,
                color: patronOrderStatusConfig[order.orderStatus]?.textColor,
                paddingLeft: "4px",
              }}
            />
          </Box>
        </Box>
        <Box sx={{ mx: 2, my: 1 }}>
          {order.vendorOrders
            .slice(0, 2)
            .map((vendorOrder: VendorOrder, index: any) => {
              return (
                <Typography
                  key={`${vendorOrder._id}_${index}`}
                  fontSize={14}
                  fontWeight={500}
                  color={"#300304"}
                >
                  {vendorOrder?.vendor?.tradingName}
                </Typography>
              );
            })}
          {order.vendorOrders.length > 2 && (
            <Typography fontSize={12} fontWeight={600} color={"#300304"}>
              {`+${order.vendorOrders.length - 2} more`}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OrderCard;

/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { ApolloError, DocumentNode, useMutation } from "@apollo/client";
import { Auth } from "aws-amplify";
import { isTokenExpired } from "../utils/refresh-token";

interface Props {
  mutation: DocumentNode;
  refetchQueries?: any;
  onError?: (e: any) => void;
  onCompleted?: (data: any) => void;
}

interface IMutateReq {
  variables?: any;
}

interface UseQueryHookResponse {
  data: any;
  loading: boolean;
  error?: ApolloError;
  mutate: (data: IMutateReq) => Promise<void>;
}

export const useMutationHook = ({
  mutation,
  refetchQueries = [],
  onError = () => { },
  onCompleted = () => { },
}: Props): UseQueryHookResponse => {
  const [gqlMutate, { data, loading, error }] = useMutation(mutation, {
    refetchQueries,
    onCompleted: (data) => {
      onCompleted(data);
    },
    onError: (e) => {
      console.log(e);
      onError(e);
    },
  });

  const mutate = async ({ variables }: IMutateReq) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.accessToken?.jwtToken;

    if (isTokenExpired(token)) {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const newToken = user?.signInUserSession?.accessToken?.jwtToken;
      await gqlMutate({
        context: {
          headers: {
            authorization: newToken,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          },
        },
        variables,
      });
      return;
    }
    await gqlMutate({
      context: {
        headers: {
          authorization: token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      },
      variables,
    });
  };

  return { data, loading, mutate, error };
};
